// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";


// Home layout

// Dark Home Layout 
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import About from "./elements/About";
import Contact from "./elements/Contact";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

/*
// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";

// Blocks Layout

import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
*/


import { BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// Portfolio details
import PortfolioPage from './elements/portfolio/PortfolioPage';
import Turnside from "./elements/portfolio/Posts/Turnside";
import MiscProjects from "./elements/portfolio/Posts/MiscProjects";
import Hexit from "./elements/portfolio/Posts/Hexit";
import MagicGame from "./elements/portfolio/Posts/MagicGame"
import DisplayEstimation from './elements/portfolio/Posts/DispEst';
import ThisSite from './elements/portfolio/Posts/ThisSite';
import SOPGame from './elements/portfolio/Posts/SOPGame';
import IAMProjects from './elements/portfolio/Posts/IAMProjects';
import Intertwined from './elements/portfolio/Posts/Intertwined';
import GregotClimb from "./elements/portfolio/Posts/GregotClimb";
import DrSpoof from './elements/portfolio/Posts/DrSpoof';
import KeysOfObliteration from './elements/portfolio/Posts/KeysOfObliteration';
import MakingConnections from './elements/portfolio/Posts/MakingConnections';
import NightOfKingSam from './elements/portfolio/Posts/NightOfKingSam';
import SpaceRPS from './elements/portfolio/Posts/SpaceRPS';
import ToTheEdge from './elements/portfolio/Posts/ToTheEdge';
import InnerMatter from './elements/portfolio/Posts/InnerMatter';
import TechnicalArtPortfolio from './elements/portfolio/MainPages/TechnicalArtPortfolio';
import SoftwareDevelopmentPortfolio from './elements/portfolio/MainPages/SoftwareDevelopmentPortfolio';
import Ecotopia from "./elements/portfolio/Posts/Ecotopia";

// Blog posts
import post0 from "./elements/blog/Posts/post0";

import Purpose from './elements/Purpose';
import IAMProject2 from './elements/IAMProject2';

// Cache Busting and Version Control
import packageJson from '../package.json';
import CacheBuster from './component/common/CacheBuster';

global.appVersion = packageJson.version;

class Root extends Component{
    render(){

        return(
            
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // Refresh immediately upon version missmatch
                    refreshCacheAndReload();
                }

                return (
                    <BrowserRouter basename={'/'}>
                    <Switch>
                        <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkPortfolioLanding}/>

                        {/* Element Layot */}
                        {/*<Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/>*/}

                        <Route exact path={`${process.env.PUBLIC_URL}/softwarePortfolio`} component={SoftwareDevelopmentPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/technicalArtPortfolio`} component={TechnicalArtPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/turnside`} component={Turnside}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/miscProjects`} component={MiscProjects}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/hexit`} component={Hexit}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/magicGame`} component={MagicGame}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/dispEst`} component={DisplayEstimation}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/thisSite`} component={ThisSite}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/purpose`} component={Purpose}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/IAMProject2`} component={IAMProject2}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/sopGame`} component={SOPGame}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/IAMProjects`} component={IAMProjects}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/intertwined`} component={Intertwined}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/gregotClimb`} component={GregotClimb}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/innerMatter`} component={InnerMatter}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/nightOfKingSam`} component={NightOfKingSam}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/makingConnections`} component={MakingConnections}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/drSpoof`} component={DrSpoof}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/spaceRPS`} component={SpaceRPS}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/toTheEdge`} component={ToTheEdge}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/keysOfObliteration`} component={KeysOfObliteration}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/ecotopia`} component={Ecotopia}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/blog/0`} component={post0}/>

                        
                        

                        {/* Blocks Elements  */}
                        {/*<Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}/>*/}


                        
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </BrowserRouter>
                );
                }}
            </CacheBuster>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();   