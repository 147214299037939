import React, { Component } from "react";
import YouTube from 'react-youtube';

class TechArtReel extends Component{
    constructor () {
        super()
    }

    render(){
        return (
            <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Technical Art Reel</h2>
                    <YouTube videoId={'CO72KDIf4UE'} iframeClassName={'youtubeIframe'}/>

                </div>

                <h3>Reel Breakdown</h3>
                <p style={{color:'#FFFFFF'}}><b>(0:03-0:15)</b> - I sculpted the character in ZBrush, retopologized/unwrapped in Maya, textured in Substance Painter, and rigged and animated in Maya. I wrote some MEL expressions for secondary motion on the antennas. I generated and textured the environment procedurally in Houdini, and used Maya for the fog/particle effects.</p>
                <p style={{color:'#FFFFFF'}}><b>(0:16-0:29)</b> - I created all visible effects/vertex distortions in Unity's ShaderGraph. I also used ShaderGraph to create the flashing colors on the road and colorful sun skybox. I used VFXGraph for the particles. The aim was to convey the passage of time into a psychedelic night as the player went further down the road.</p>
                <p style={{color:'#FFFFFF'}}><b>(0:30-0:35)</b> - I created the fading out of existence effect with Unity's ShadergGraph, and used VFXGraph for the particles.</p>
                <p style={{color:'#FFFFFF'}}><b>(0:36-0:42)</b> - I modelled all the meshes in Blender so I could geometrically distort them/apply color rhythmically with Unity's ShaderGraph. I made the curtains spin via vertex transformations. The orb in the center was synchronized to music via VFXGraph.</p>
                <p style={{color:'#FFFFFF'}}><b>(0:43-0:50)</b> - My teammates on this project gave me meshes which I animated/added effects to via ShaderGraph and VFX Graph. I made the runic symbols glow radially, the snakes on the side pulse with color, the keys form and disintegrate in and out of existence, the trails on the attacks, as well as all the other visible effects.</p>
                <p style={{color:'#FFFFFF'}}><b>(0:50-1:00)</b> - I coded the visual effects for each tile in HLSL, such as making them propagate with distance from the player, having some tiles pulse in and out of existence, etc. I also generated the blue prism-structures on the side via C# and animated them through a HLSL Geometry Shader.</p>
                <p style={{color:'#FFFFFF'}}><b>(1:00-1:12)</b> - I made the volumetric lighting and fog effects via ShaderGraph and Unity's particle systems, as well as effects for the laser and computer monitor.</p>
                <p style={{color:'#FFFFFF'}}><b>(1:13-1:19)</b> - I used ShaderGraph to create patterns on the trails which pulsated with each correct/incorrect hit of the aliens. The extent of the arrows also indicated player health.</p>
                <p style={{color:'#FFFFFF'}}><b>(1:19-1:34)</b> - I created each of these environments in a day using Blender, Substance Painter, and Unity to help my team brainstorm for our app.</p>

                <br/>
                <br/>

            </div>
        )
    }

}

export default TechArtReel;