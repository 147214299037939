import React, { Component } from "react";
import {compileImage} from "../helpers/projects";

/*
go to C:\Users\Faris ElRayes\Documents\Digital-Portfolio\site\public\assets\scss\elements\_portfolio.scss
to see original image gifs
*/

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = this.props.list;
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                {/*<div className={`thumbnail ${value.image}`}></div>*/}
                                {compileImage(value, this.props.imgOpacity)}
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <h4><a href={value.href}>{value.title}</a></h4>
                                    <p>{value.tools}</p>
                                    <h5 style={{'color':'#ffffff'}}>{value.category}</h5>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.href}>View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;