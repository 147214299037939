import React from 'react';
import TechArtReel from '../Posts/TechArtReel';
import PortfolioPage from '../PortfolioPage';

const TechnicalArtPortfolio = (props) => {
    return (
        <PortfolioPage {...props} title={`Projects`} list={'technical art'} >
            <TechArtReel/>
        </PortfolioPage>
    )
}

export default TechnicalArtPortfolio;