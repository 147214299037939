import React, { Component } from "react";
import Post from "./Templates/post";

class DisplayEstimation extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>

                {/* Start Portfolio Details */}
                <Post shortname="dispEst">
                <div className="inner">
                    <p className="subtitle">This was a semester-long project I took as part of The University of Melbourne's Advanced Studies in Computing program.</p>
                    <h2>Abstract</h2>
                    <p className="subtitle">This project aims to produce a method for calibrating head-tracked head-mounted
eye trackers to multiple display cases in which the exact dimensions and positioning
of displays are unknown. This enables the use of gaze interaction and immersive firstperson motion tracked perspective projections. The desired outcome of the calibration
procedure is a precise positional and dimensional placement of each viewed display in a
Unity environment. The positioning of the displays in application space is determined
using computer vision techniques. First the homography matrix of the eye tracker’s
scene view camera to a viewed display is identified across different viewing angles.
Then epipolar geometry techniques are employed to estimate the precise location of
a viewed display with respect to the scene view camera. The extrinsic parameters of
the camera are known via head tracking, allowing triangulation of display extents.
</p>
<p className="subtitle">
The development of this project is divided across a Unity library and a Python
2.7 server. The Unity library handles user interaction and streams the images of
its displays to the Python server so that feature matching may occur. The Python
server matches display images to scene cam images of a connected head-mounted eyetracker to determine the coordinates of the display extents, before providing them to
the Unity application.
</p>
<p>You can download the full report <a href="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/pdfs%2FMDPEReport.pdf?alt=media&token=ed73c593-f144-430b-8d47-36f6d0e5d460">here</a>.</p>
<p>And you may download my powerpoint presentation on the project <a href="https://drive.google.com/file/d/1SyXPvkQM4rYIWVK7m5VkwEvd7vyfxcM3/view?usp=sharing">here</a>.</p>
                    </div>
                </Post>             
            </React.Fragment>
        )
    }
}
export default DisplayEstimation;
