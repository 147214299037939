import React, { Component } from "react";
import { findProject } from "../../../helpers/projects";
import PortfolioList from "../../PortfolioList";

class RelatedWork extends Component  {
    render(){
        const project = findProject(this.props.shortname);
        if (project.related.length > 0){
            let related = [];
            for (let i = 0; i < project.related.length; i++){
                related.push(findProject(project.related[i]));
            }
            return (
                <React.Fragment>
                    <div className="portfolio-related-work pt--90 pb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <span className="theme-color font--18 fontWeight600">Related Work</span>
                                        <h2>Similar Projects</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList imgOpacity="1" styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" list={related} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }else{
            return null;
        }
    }
}

export default RelatedWork;