import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";

class Turnside extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <Post shortname='turnside'>
                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <h2>What was it?</h2>
                        <p className="subtitle">Turnside was a game I worked on in high school with other members of JMSS' Game Development Club, founded by Percy Muenjohn and I. It relied on gravity-bending as its primary mechanic as another puzzle-platformer Portal clone.</p>
                        <p className="subtitle">My roles were primary programmer, level designer and club manager. I coded the main mechanics and level prototypes. Additional active members included the talented Curtis Pavey, Marc Distel and Riley Taylor.</p>
                        <p>Unfortunately, being in the final year of high school many of us struggled to devote ample time to seeing the project's completion, and mostly parted ways after graduation.</p>
                        <br/><br/>
                        <h2>Vertical Slice</h2>
                        <p className="subtitle">You can download our 3-level vertical slice, introducing the game's core mechanics, <a href="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/zips%2FTS-VS.zip?alt=media&token=99223925-fd96-450c-acea-357954965e24">here</a>.</p>
                        <p>Or you could just watch the walkthrough below.</p>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src="/assets/images/portfolio/tsVSThumbnail.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='W9CaWcgupB4' onClose={() => this.setState({isOpen: false})} />
                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                        </div>
                        
                        {/*<div className="thumb mb--30">
                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                        </div>

                        <div className="thumb">
                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                        </div>*/}
                        <br/><br/>
                    </div>
                    <div className="inner">
                        <h2>Mystical Cave</h2>
                        <p className="subtitle">I also built a fun little cave with a winding path to demonstrate how neat this mechanic could be in natural environments. This was modelled in blender, with the path defined using beizer curves. Download it <a href="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/zips%2FTS-Cave.zip?alt=media&token=dd32fa49-05f8-47de-87a8-42dde6dcc6c7">here</a> or absorb it in gif form!</p>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2FtsCave.gif?alt=media&token=c7ad8f7f-a257-421c-b8b7-52d5e704530d" alt="Portfolio Images"/>
                        </div>
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default Turnside;
