import React, { Component } from "react";
import BlogPost from "../Templates/BlogPost";
import SlowType from "../BlogAnimations/SlowType";
import {Grid, Image} from 'semantic-ui-react';
import RevealAfterPeriod from "../BlogAnimations/RevealAfterPeriod";
import Story from "../BlogAnimations/Story/Story";
import StoryText from "../BlogAnimations/Story/StoryText";
import StoryAwaitOptionSelect from "../BlogAnimations/Story/StoryAwaitOptionSelect";
import StoryOption from "../BlogAnimations/Story/StoryOption";
import StoryClearAll from "../BlogAnimations/Story/StoryClearAll";
import StoryReveal from "../BlogAnimations/Story/StoryReveal";
import StoryDramaticReveal from "../BlogAnimations/Story/StoryDramaticReveal";
import { animateScroll } from "react-scroll";
import StoryAwait from "../BlogAnimations/Story/StoryAwait";
import Disqus from "disqus-react";
import Cookies from 'universal-cookie';

class post0 extends Component{
    constructor(props) {
        super(props);
        this.state = {
            applicationTime: 0,
            timeWhenTimelineStarted: Date.now(),
            loadedStory: this.loadStoryFromCookies() 
        }

        this.disqusShortname = "fariselrayes-com"
        this.disqusConfig = {
            url: "https://fariselrayes.com",
            identifier: "/blog/0",
            title: "Post 0: Say hi to blog!"
        };
    }
    
    componentDidMount() {
        this.interval = setInterval(() => this.setState({ applicationTime: Date.now() }), 100);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    saveStoryInCookies(storyState){
        const cookies = new Cookies();

        cookies.set('post0', JSON.stringify(storyState), { path: '/' });
    }

    loadStoryFromCookies(){
        const cookies = new Cookies();
        let storyState = cookies.get('post0');
        if (storyState == null)
            return null;
        else{
            return storyState;
        }
    }

    render(){
        return(
            <React.Fragment>

                {/* Start Portfolio Details */}
                <BlogPost title="Say hi to blog!" date="12/21/21">
                    <div className="inner">
                        <Story startTime={0} typeSpeed = {40} color1="#737373" color2="#d4d4d4" timeBetweenLines={0.5} completionState={this.state.loadedStory} onComplete={this.saveStoryInCookies}>
                            <StoryText>Hi,</StoryText>
                            <StoryAwait duration={1.5}/>
                            <StoryClearAll/>
                            <StoryText>Hello internet!</StoryText>
                            <StoryAwait duration={1.5}/>
                            <StoryClearAll/>
                            <StoryText>My dearest potential employer,</StoryText>
                            <StoryText>It is of the utmost importance that I grace you on this splendid occasion, with the fullest experience of my hot takes and general game design expertise.</StoryText>
                            <StoryAwaitOptionSelect>
                                <StoryOption title="I'm not your potential employer">
                                    <StoryText>It may be the case that you are merely a supporting friend or mentor, in which event I would greatly appreciate feedback on my execution in this blogged realm, thus I may catch myself a jobbed offered. You may at any time contact me directly, or post in the comments section below (once unlocked).</StoryText>
                                    <StoryAwaitOptionSelect>
                                        <StoryOption title="Here’s some feedback: write like an actual person"/>
                                    </StoryAwaitOptionSelect>
                                </StoryOption>
                                <StoryOption title="I wish I ran a company so I could send you a rejection letter">
                                    <StoryText>If you feel ever so compelled, dearest reader, I am open to feedback as to how to improve the state of this blogged realm. You may at any time contact me directly, or post in the comments section below (once unlocked).</StoryText>
                                    <StoryAwaitOptionSelect>
                                        <StoryOption title="Here’s some feedback: write like an actual person"/>
                                    </StoryAwaitOptionSelect>
                                </StoryOption>
                                <StoryOption title="I don't hire people just because they sound British"/>
                            </StoryAwaitOptionSelect>
                            <StoryClearAll/>
                            <StoryText>Alright I’ll be frank. I’m here, you’re here, in this strange part of the internet.</StoryText>
                            <StoryText>Why?</StoryText>
                            <StoryText>Well, this is little Faris.</StoryText>
                            <StoryReveal>
                                <Image src="\assets\images\blog\lilFaris.png" alt="Portfolio Images" style={{width:"40%", "margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            </StoryReveal>
                            <StoryAwait duration={1.5}/>
                            <StoryAwaitOptionSelect>
                                <StoryOption title="Cute">
                                    <StoryText>Little Faris is only slightly cuter than adult Faris. Yet still, by the laws of cuteness hierarchy, little Faris remains eligible to control adult Faris’ every whim.</StoryText>
                                </StoryOption>
                                <StoryOption title="This is emotional manipulation">
                                    <StoryText>Dear reader, you'll make Little Faris cry if you don’t help adult Faris achieve his game design dreams.</StoryText>
                                </StoryOption>
                                <StoryOption title="I don’t give jobs to children">
                                    <StoryText>Little Faris no longer exists, but his legacy still dictates adult Faris’ every whim.</StoryText>
                                </StoryOption>
                            </StoryAwaitOptionSelect>
                            <StoryText>Little Faris had a lot of fun designing games in his head with his friends. Now adult Faris must do a lot more work to achieve similar levels of fun. </StoryText>
                            <StoryText>Prepare, reader, for what we seasoned game designers refer to as a “plot twist”.</StoryText>
                            <StoryAwaitOptionSelect>
                                <StoryOption title="You are adult Faris"/>
                            </StoryAwaitOptionSelect>
                            <StoryDramaticReveal reveal="IS I!!!">Adult Faris... </StoryDramaticReveal>
                            <StoryText>Wanting to learn how to actually get things done, I went to Carnegie Mellon University’s Entertainment Technology Center. There I spoke briefly with Jesse Schell, whose book “The Art of Game Design” had a significant impact on me. He described my current situation perfectly:</StoryText>
                            <StoryText>“You’re a software developer, who’s learning technical art, with the aim of getting into game design.”</StoryText>
                            <StoryAwaitOptionSelect>
                                <StoryOption title="I pay a lot more for Software Developers">
                                    <StoryText>I… Uhh… Wait why aren’t I looking to specialize in $oftware development again?</StoryText>
                                    <StoryText>I'm sure there was probably a good reason...</StoryText>
                                    <StoryText>Whatever it was, game design is my ultimate passion, and technical art is my design medium. I enjoy the technical challenges, and love watching colorful things dance around. Being a $oftware developer is still supremely helpful in prototyping/communicating with others.</StoryText>
                                </StoryOption>
                                <StoryOption title="Why don't you just commit to Technical Art?">
                                    <StoryText>I’m loving the study of technical art, creating cool effects, challenging myself in creative and logical ways. But when I ask myself “what for”, I am motivated to create things which tell transformative stories in vibrant worlds. I don’t see technical art as my ends, but my design medium.</StoryText>
                                </StoryOption>
                                <StoryOption title="Why don't you just study Game Design?">
                                    <StoryText>This isn’t to say I’m not studying game design. Sure, most of my electives are currently targeted at enhancing my tech art skills, but I’ve got several design projects planned into the next year, and I’m reading and studying design on the side as often as I can. But when it comes to it, I am motivated to create things which tell transformative stories in vibrant worlds. I don’t see technical art as my ends, but my design medium.</StoryText>
                                </StoryOption>
                            </StoryAwaitOptionSelect>
                            <StoryText>So, to contribute to the polishing of my design skills, and enhance my chances at being offered game design opportunities, Jesse Schell recommended I start a blog.</StoryText>
                            <StoryText>And here we are.</StoryText>
                            <StoryText>I have several posts planned. Game reviews, analysis, general topics in the industry. You might not agree with me. I’ll probably be wrong sometimes.</StoryText>
                            <StoryText>I’ll probably be wrong a lot.</StoryText>
                            <StoryText>But this feels like the start of something important.</StoryText>
                            <StoryAwaitOptionSelect>
                                <StoryOption title="Good luck!"/>
                                <StoryOption title="Okay you’ve won me over, have a job."/>
                            </StoryAwaitOptionSelect>
                            <StoryText>And if you ever want to be in touch with me, please, I’m open to feedback and conversations. You can email me at fariselrayes@gmail.com, or post something in the comments below!</StoryText>
                            <StoryDramaticReveal reveal="Gamelord Faris">Until next time,</StoryDramaticReveal>
                            
                            
                            <StoryReveal>
                                {/* Blog Discussion Area */}
                                <Disqus.DiscussionEmbed
                                    shortname={this.disqusShortname}
                                    config={this.disqusConfig}
                                />
                            </StoryReveal>
                        </Story>
                    </div>
                </BlogPost>

            </React.Fragment>
        )
    }
}
export default post0;
