import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import {webMFormattedSimple} from "../../helpers/resourceFinder";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {Grid, Image} from 'semantic-ui-react';
import 'react-pro-sidebar/dist/css/styles.css';

class ToTheEdge extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          scrollPos: 0,
          video: '',
          videoStart: 0,
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {      
        this.setState({
            scrollPos: window.scrollY,
        })
    }

    openModal (videoURL, start = 0) {
        this.setState({isOpen: true, video:videoURL, videoStart:start})
    }
    render(){
        return(
            <React.Fragment>
                
                <Post shortname='toTheEdge' sidebar={<ProSidebar toggled="false" id="portfolio-sidebar" style={this.state.scrollPos < 600 ? {"margin-left":"-12%"} : {}}>
                        <Menu iconShape="square">
                        <MenuItem><a href="#desc">Development</a></MenuItem>
                        <MenuItem><a href="#game">Final Game</a></MenuItem>
                        </Menu>
                    </ProSidebar>}>
                    
                    {/* For playing videos */}
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.video} youtube={{start:this.state.videoStart}} onClose={() => this.setState({isOpen: false})} />
                        </div>
                    </div>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                    <p>This was done for Round 4 of Carnegie Mellon University's Building Virtual Worlds course.</p>
                    <br id="desc"/>
                    <p className="subtitle">
                        <br/><b>Platform:</b> The CAVE (Room surrounded with 3 projector screens with a motion platform on the floor) and VIVE Trackers.
                        <br/><b>Timeframe:</b> Two weeks.
                        <br/><b>Teammates:</b>Qiujie Wu (other programmer), Zizhuo Wang (artist), Qisheng Chen (artist), Nelson Nie (sound designer).
                    </p>
                    <p className="subtitle">A motion platform. A couple Vive trackers. A surrounding projector system. How do you tell a story? You turn to your teammates, discover one of them did their undergrad in creative writing, and thank your lucky stars.</p>
                    <p className="subtitle" style={{fontStyle:'italic'}}>A surrounding projector system…</p>
                    <p className="subtitle">Wait a minute! I was supposed to do a research project in my undergrad on one of these, before I lost access to the HCI lab due to Covid. <a href="/dispEst">I had already studied all the math for off axis projection.</a> Now I get to actually use it!</p>
                    <p className="subtitle">Let's make this thing immersive!</p>
                    <img style={{"margin-left":"auto","margin-right":"auto", "display":"block", "width":"100%"}} src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2FOffAxisProjection.gif?alt=media&token=00e56fb7-f0e7-47fe-a52b-81c54967d5e5" alt="Portfolio Images"/>
                    <p>My off-axis projection asset, with its cute 'lil gizmos.</p>
                    <p className="subtitle">We decided we were going to set our adventure at sea, and thus the motion platform would sway with that of a boat. The pistons of the platform were individually indexed and extensions were set directly via voltage. I was having none of this. I built a visualizer Gizmos for the platform, which would automatically sync its orientation and extension of the platform pistons. This asset was found useful by other teams.</p>
                    <img style={{"margin-left":"auto","margin-right":"auto", "display":"block", "width":"100%"}} src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2FMotionPlatform.gif?alt=media&token=f90bc39d-27ce-49b2-8915-51e87d10972f" alt="Portfolio Images"/>
                    <p className="subtitle">For an ocean game, I wanted to make the water shader really cool! Unfortunately the CAVE doesn't yet support URP, so I had to code the water directly in HLSL. I'm pleased with the end result (considering it was coded from scratch in under a day, and had to use simple enough height functions that the CPU could recompute the same values for buoyant objects).</p>
                    <p className="subtitle">I also built the Sword slash tracking for the Vive trackers, and the overall gampeplay event system (timing dialogue, animations and detecting player inputs).</p>
                
                    <br/><br id="game"/><br/><br/>
                    <h2>To the Edge of the World!</h2>
                    <Image src="\assets\images\portfolio\Team_68_CoverPhoto.png" alt="Portfolio Images" style={{width:"100%"}}/>
                    <p className="subtitle">
                        <br/><b>Gameplay video: </b>
                        <br/><p>The weird screen distortion is due to off-axis projection. The world looks deep and 3D from Chao Li's perspective.</p>
                    </p>
                        <div className="thumb position-relative mb--30">
                            <img src="\assets\images\portfolio\toTheEdgeThumb.PNG" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <button className="video-popup position-top-center" onClick={() => this.openModal('ZomOEcbZ-wM')}><span className="play-icon"></span></button>
                        </div>
                    <br/><br/>

                        
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default ToTheEdge;
