import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import {webMFormattedSimple} from "../../helpers/resourceFinder";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {Grid, Image} from 'semantic-ui-react';
import 'react-pro-sidebar/dist/css/styles.css';

class InnerMatter extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          scrollPos: 0,
          video: '',
          videoStart: 0,
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {      
        this.setState({
            scrollPos: window.scrollY,
        })
    }

    openModal (videoURL, start = 0) {
        this.setState({isOpen: true, video:videoURL, videoStart:start})
    }
    render(){
        return(
            <React.Fragment>
                
                <Post shortname='innerMatter' sidebar={<ProSidebar toggled="false" id="portfolio-sidebar" style={this.state.scrollPos < 600 ? {"margin-left":"-12%"} : {}}>
                        <Menu iconShape="square">
                            <MenuItem><a href="#software_development">Softare Development</a></MenuItem>
                            <MenuItem><a href="#technical_art">Technical Art</a></MenuItem>
                        </Menu>
                    </ProSidebar>}>
                    
                    {/* For playing videos */}
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.video} youtube={{start:this.state.videoStart}} onClose={() => this.setState({isOpen: false})} />
                        </div>
                    </div>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p className="subtitle">Inner Matter is a Meditation VR Experience made in collaboration with <a href="https://equahealth.io/">Equa Health</a> exploring live breathing biofeedback. </p>
                        <p className="subtitle">I served as the main in-engine software developer and a technical artist. </p>
                        <h3><a href="https://projects.etc.cmu.edu/inner-matter/">You can find the official project website here.</a></h3>

                        <br id="software_development"/> <br/>
                        <h1>Software Development</h1>
                        <p className="subtitle">As the main in-engine programmer, I built the event system with an ordered graphical interface and a script for binding event enums to their callback functions and completion checks for events which halt. I distinguished between voice over events, scripting events, pauses, and events which halt and await user input.</p>
                        <Image src="\assets\images\portfolio\innerMatter\events.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block", width:"40%"}}/>
                        <p className="subtitle">I also made a gesture interaction system, and a UI display system which naturally curves 2D assets to the view of the user in VR.</p>
                        <Image src="\assets\images\portfolio\innerMatter\curvedScreen.png" alt="Portfolio Images" style={{width:"70%"}}/>
                        <Image src="\assets\images\portfolio\innerMatter\gestureSystem.png" alt="Portfolio Images" style={{width:"30%"}}/>
                        <p className="subtitle">I designed and implemented the algorithm which converted breath sensor input to a success metric according to a nice curve.</p>
                        <Image src="\assets\images\portfolio\innerMatter\respirationAlgorithm.png" alt="Portfolio Images" style={{width:"100%", border: "5px solid #555"}}/>
                        <p className="subtitle">I also built several tools to make our pipeline easier overall.</p>
                        <Image src="\assets\images\portfolio\innerMatter\pipelineTool1.png" alt="Portfolio Images" style={{width:"50%"}}/>
                        <Image src="\assets\images\portfolio\innerMatter\pipelineTool2.png" alt="Portfolio Images" style={{width:"50%"}}/>
                        <Image src="\assets\images\portfolio\innerMatter\pipelineTool3.png" alt="Portfolio Images" style={{width:"50%"}}/>


                        <br id="technical_art"/> <br/>
                        <h1>Technical Art</h1>
                        <p className="subtitle">In the early stages, I rapidly prototyped several potential environments using Blender and Unity. Each was made in a day, entirely from scratch (to the polygon), with placeholder meditation guides.</p>
                        <p>Prototype 1: Cave with Spring</p>
                        <div className="thumb position-relative mb--30">
                            <img src="\assets\images\portfolio\innerMatter\caveSpringThumb.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <button className="video-popup position-top-center" onClick={() => this.openModal('29cbQLrWIEE')}><span className="play-icon"></span></button>
                        </div>
                        <p>Prototype 2: Canyons with Floating Rocks</p>
                        <div className="thumb position-relative mb--30">
                            <img src="\assets\images\portfolio\innerMatter\canyonsThumb.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <button className="video-popup position-top-center" onClick={() => this.openModal('pjS5--R-w_w')}><span className="play-icon"></span></button>
                        </div>
                        <p>Prototype 3: Monastery</p>
                        <div className="thumb position-relative mb--30">
                            <img src="\assets\images\portfolio\innerMatter\monasteryThumb.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <button className="video-popup position-top-center" onClick={() => this.openModal('Ym7hzRHXYCs')}><span className="play-icon"></span></button>
                        </div>
                        <p>Prototype 4: Waterfall (this concept was selected for the final location)</p>
                        <div className="thumb position-relative mb--30">
                            <img src="\assets\images\portfolio\innerMatter\jungleThumb.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <button className="video-popup position-top-center" onClick={() => this.openModal('QSiIpMOTFHE')}><span className="play-icon"></span></button>
                        </div>
                        <p className="subtitle">Along the way, I developed several of the shaders and effects, like this cool flower made with ShaderGraph and Substance Painter originally intended to communicate heart rate. </p>
                        <img style={{"margin-left":"auto","margin-right":"auto", "display":"block", "width":"30%"}} src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2FbiofeedbackFlower.gif?alt=media&token=1510c448-0859-41d5-916d-09ef4c08ebc2" alt="Portfolio Images"/>
                        <p className="subtitle">Or this waterfall which required me to learn to make flow maps in Houdini.</p>
                        <img style={{"margin-left":"auto","margin-right":"auto", "display":"block", "width":"60%"}} src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2FhrvrWaterfall.gif?alt=media&token=e081049b-e196-4b12-824e-2aac2d90a46c" alt="Portfolio Images"/>
                        
                        
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default InnerMatter;
