import React, { Component } from "react";

class SlowType extends Component{
    constructor(props) {
        super(props);
        this.state = {
            visibleText : "",
            coloredText : ""
        }
    }
    
    componentDidMount() {
        this.interval = setInterval(() => {
            this.updateText();
        }, 100);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    updateText(){
        var timeWithStartOffset = Date.now() - (this.props.startTime ? this.props.startTime * 1000 : 0);
        var typePercentage = (timeWithStartOffset - this.props.timelineOrigin) / (this.props.typeTime * 1000);
        var coloredTypePercentage = (timeWithStartOffset+500 - this.props.timelineOrigin) / (this.props.typeTime * 1000);
        var erasePercentage = 0;
        if (this.props.pauseAndErase){
            erasePercentage = (timeWithStartOffset - (this.props.timelineOrigin + this.props.pauseTime * 1000 + this.props.typeTime * 1000)) / (this.props.eraseTime * 1000);
        }

        var currentText = this.props.pauseAndErase ?
            (erasePercentage > 0 ?
                this.props.children.substring(0, Math.floor((1-erasePercentage) * this.props.children.length))
                :
                this.props.children.substring(0, Math.floor(typePercentage * this.props.children.length))
            )
        :
            this.props.children.substring(0, Math.floor(typePercentage * this.props.children.length));

        
        
        if (!(currentText === this.state.visibleText)){
            var cText = "";
            if (!(this.props.pauseAndErase && erasePercentage > 0)){
                cText = this.props.children.substring(0, Math.floor(coloredTypePercentage * this.props.children.length));
                cText = cText.substring(currentText.length, cText.length);
            }
            this.setState({visibleText:currentText, coloredText:cText});
            this.screenBottom.scrollIntoView({behavior:"smooth"});
        }
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.state.visibleText
                }
                <span style={{color:this.props.color1}}>{
                    this.state.coloredText.substring(0, this.state.coloredText.length/2)
                }    </span> <span style={{color:this.props.color2}}>{
                    this.state.coloredText.substring(this.state.coloredText.length/2, this.state.coloredText.length)
                }    </span>  
                <div id="screenBottom" ref={(el) => { this.screenBottom = el; }}/>
            </React.Fragment>
        )
    }
}
export default SlowType;
