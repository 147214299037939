import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import {webMFormattedSimple} from "./../../helpers/resourceFinder";

class IAMProjects extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <Post shortname='iamProj'>
                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p className="subtitle"><b>Interactive Art Media </b>was a subject I took in the final semester of my undergraduate degree.</p>
                        <p className="subtitle">There was a lot of freedom granted in this unit, so I used it as an excuse to develop my shader skills.</p>
                        <h2>Project One - Music Room</h2>
                        <p className="subtitle">For the first submission, I created a room which vibes with selected songs. Unfortunately I can't upload it here due to all the copyrighted music (although you may contact me if interested). You can see the demo video below.</p>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src="/assets/images/portfolio/musicRoomThumb.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='viIE8HQzdPQ' onClose={() => this.setState({isOpen: false})} />
                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                        </div>
                        <br/><br/>
                    </div>
                    <div className="inner">
                        <p className="subtitle">To do this I created 3 original shaders.</p>
                        <h3>Shader 1 - The wobbly cubes</h3>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            {webMFormattedSimple("IAMFloorWobble", {height:"400px"})}
                            <div style={{'height':'400px'}}/>
                        </div>
                    </div>
                    <div className="inner">
                        <p className="subtitle">I modeled the walls, floors and ceiling in blender. I tweaked UV parameters to make positions easier to identify, and used a grid coordinate system along with player position and song BPM to create a cool wobble effect. This was all done in ShaderGraph.</p>
                        <h3>Shader 2 - The spinning curtains</h3>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            {webMFormattedSimple("IAMCurtain", {height:"400px"})}
                            <div style={{'height':'400px'}}/>
                        </div>
                    </div>
                    <div className="inner">
                        <p className="subtitle">I modeled a column of rectangles and assigned each one a different UV y value. I then made a rotation function which slides up and down along them, and makes them disappear according to song BPM, all in ShaderGraph.</p>
                        <h3>Shader 3 - The musical particle ball</h3>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            {webMFormattedSimple("IAMBallStill", {height:"300px"})}
                            <div style={{'height':'300px'}}/>
                            {webMFormattedSimple("IAMBallDance", {height:"300px"})}
                            <div style={{'height':'300px'}}/>
                        </div>
                    </div>
                    <div className="inner">
                        <p className="subtitle">I used VFX graph to create a ball of particles with 3 different orbiting sections, each of which with a repulsive force proportional to a frequency range within the music.</p>
                        <h2>Project Two - Software Mirror</h2>
                        <p className="subtitle">For the second submission, I created an image effect shader using the webcam's texture as input to create a neat WebGL software mirror. It's available <a href="IAMProject2">here</a>.</p>
                        <h2>Major Project - Intertwined</h2>
                        <p className="subtitle">For the major project I teamed up with the infinitely talented <a href="https://www.facebook.com/bodhihawken/">Bodhi Hawken</a> in order to produce a short game. You can find more details <a href="intertwined">here</a>.</p>
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default IAMProjects;
