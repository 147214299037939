import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import {webMFormattedSimple} from "../../helpers/resourceFinder";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {Grid, Image} from 'semantic-ui-react';
import 'react-pro-sidebar/dist/css/styles.css';

class NightOfKingSam extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          scrollPos: 0,
          video: '',
          videoStart: 0,
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {      
        this.setState({
            scrollPos: window.scrollY,
        })
    }

    openModal (videoURL, start = 0) {
        this.setState({isOpen: true, video:videoURL, videoStart:start})
    }
    render(){
        return(
            <React.Fragment>
                
                <Post shortname='nightOfKingSam' sidebar={<ProSidebar toggled="false" id="portfolio-sidebar" style={this.state.scrollPos < 600 ? {"margin-left":"-12%"} : {}}>
                        <Menu iconShape="square">
                            <MenuItem><a href="#desc">Task Description</a></MenuItem>
                            <MenuItem><a href="#game">Final Game</a></MenuItem>
                            <MenuItem><a href="#reflection">Design Reflection</a></MenuItem>
                        </Menu>
                    </ProSidebar>}>
                    
                    {/* For playing videos */}
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.video} youtube={{start:this.state.videoStart}} onClose={() => this.setState({isOpen: false})} />
                        </div>
                    </div>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p>This was done for Round 0 of Carnegie Mellon University's Building Virtual Worlds course.</p>

                        <br id="desc"/> <br/>
                        <p className="subtitle">
                            <b>Task:</b> Create a game using a limited set of provided assets, utilizing a list of basic game structures including coroutines, multiple scenes, camera controls and animations.
                            <br/><b>Platform:</b> PC.
                            <br/><b>Timeframe:</b> A weekend.
                            <br/><b>Teammates:</b> None.
                        </p>
                        <Grid columns={3}>
                        <Grid.Row verticalAlign='middle' style={{"flex-wrap":"nowrap"}}> 
                            <Grid.Column style={{width:"33%"}}>
                                <Image src="\assets\images\portfolio\Haruko.PNG" alt="Portfolio Images" style={{width:"100%"}}/>
                            </Grid.Column>
                            <Grid.Column style={{width:"33%"}}>
                                <Image src="\assets\images\portfolio\Sam.PNG" alt="Portfolio Images" style={{width:"100%"}}/>
                            </Grid.Column>
                            <Grid.Column style={{width:"33%"}}>
                                <Image src="\assets\images\portfolio\Zombie.PNG" alt="Portfolio Images" style={{width:"100%"}}/>
                            </Grid.Column>
                        </Grid.Row>
                        </Grid>
                        <Image src="\assets\images\portfolio\castleKit.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p>Some of the included assets</p>
                        <p className="subtitle"><b>I built a game from these assets using Unity C# over a weekend.</b> My idea: You play as a lead zombie, during a zombie takeover of a castle. King Sam has built several destructible clone machines. Clones will attack zombies, damaging them. You can free zombie allies from cages, or convert dead Sam clones into zombies, with the hopes of accumulating a large enough army to defeat King Sam in the final boss room.</p>
                        <p className="subtitle">In our first class of BVW, we were taught the value of rapid development and experimentation in an exercise involving spaghetti towers. Kindergarteners tend to build the tallest structures as they just go for it, without any planning whatsoever. So, like a kindergartener armed with spaghetti, I threw the building blocks together one-at-a-time designing the level somewhat improvisationally. The final castle worked well, although there were certainly strange corners that didn’t connect properly.</p>
                        <p className="subtitle">I also made a shader for the starry skybox, and vfx for the torches and Sam clones’ dash attacks. I also became highly familiar with Unity’s Navmesh, which I used for AI pathfinding. Without further ado, here’s the final game:</p>
                        <br id="game"/><br/><br/><br/>
                        <h2>Night of King Sam's Zombie Clone Takeover</h2>
                        <p className="subtitle">
                            <b>Download link:</b> <a href="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/zips%2FNight%20of%20King%20Sam_s%20Zombie%20Clone%20Takeover.zip?alt=media&token=2b8fcebc-150b-4fdf-8e74-6b71e6ca73a4">here</a>
                            <br/><b>Gameplay video: </b>(unedited footage)
                        </p>
                        <div className="thumb position-relative mb--30">
                            <img src="\assets\images\portfolio\kingSamVidThumb.PNG" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <button className="video-popup position-top-center" onClick={() => this.openModal('zNa3mjH5otw')}><span className="play-icon"></span></button>
                        </div>
                        <br/><br/>
                        <br id="reflection"/> <br/>
                        <p className="subtitle">
                                <b>Reflections:</b>
                        </p>
                        <p className="subtitle">This game could use several improvements from a variety of perspectives. Here are a few I’ve come up with:</p>
                        <p className="subtitle">
                            <ul>
                                <li>UI/UX
                                    <ul>
                                        <li>A clearer escalation of mechanics should be provided to avoid literal walls of text.</li>
                                        <li>Lack of precision on attacks/zombie conversion can be frustrating for the player.</li>
                                        <li>Camera angles are often cramped. More care should be taken in level design to try and encourage more interesting angles.</li>
                                        <li>There is a lack of continual reinforcement of the player’s goal, or how close they are to achieving it. They should be trying to mass a large enough army to defeat King Sam, however it is hard to gauge at any time if they are ready. As the developer, I usually aim for an army of over ~40 zombies, but the player cannot possibly know this.</li>
                                        <li>It is easy to get stuck on certain surfaces that should be easily walkable.</li>
                                    </ul>
                                </li>
                                <li>Gameplay
                                    <ul>
                                        <li>Problem: The game aims to immerse the player in a power fantasy of being a zombie lord. However, most of the playtime is spent converting individual Sam clones, building your army on a small, somewhat unsatisfactory scale, rather than commanding a large army.
                                            <ul>
                                                <li>The level could be more dispersed, and the player could have more control commanding individual units of zombies.</li>
                                                <li>Sam clone conversion could be delegated.</li>
                                            </ul>
                                        </li>
                                        <li>The ending is buggy and unsatisfactory. The player will likely want to sit on the throne and be hailed by their zombie compatriots, but I forgot to even make the throne collidable.</li>
                                        <li>Unintelligent AI can greatly detract from the fantasy.</li>
                                    </ul>
                                </li>
                                <li>Playtesting
                                    <ul>
                                        <li>There was none. This game could be unplayable for many people, with inherent design flaws I don’t even know about.</li>
                                    </ul>
                                </li>
                                <li>Visual Design
                                    <ul>
                                        <li>Cold fog in the courtyard, contrasted with the warm torchlight, could have added to the drama and visual aesthetic of the combat.</li>
                                        <li>Volumetric moonlit lighting through the skylight in the throne room would have heightened the drama and reinforced the game’s night time theming.</li>
                                        <li>More emphasis could have been placed on King Sam’s crown, and having it transferred to the player upon victory.</li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                        <p className="subtitle">Interestingly, I discovered a paradoxical design relationship in the initial hallway. I placed only a single enemy and two freeable allies. The aim here was to teach the player how much better your zombie friends are at fighting the Sam clones than you. Thus the game should be about freeing and converting as many clones as possible. However, if the player does try to take matters into their own hands in fighting the clones, they will be inevitably frustrated with the difficulty of player combat, leading to a feeling of weakness in a game about power.</p>
                        <p className="subtitle">Despite all these issues, for a game made over a weekend, I’m satisfied with the result. I certainly learned a lot about pathfinding, lighting, animation controllers, level design and my own abilities to rapidly prototype. Also, this was the only round of BVW where we worked alone, so perhaps my subconscious found it fitting to produce a game about personal weakness and strength in numbers.</p>

                        
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default NightOfKingSam;
