import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import {webMFormattedSimple} from "../../helpers/resourceFinder";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {Grid, Image} from 'semantic-ui-react';
import 'react-pro-sidebar/dist/css/styles.css';

class GregotClimb extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          scrollPos: 0,
          video: '',
          videoStart: 0,
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {      
        this.setState({
            scrollPos: window.scrollY,
        })
    }

    openModal (videoURL, start = 0) {
        this.setState({isOpen: true, video:videoURL, videoStart:start})
    }
    render(){
        return(
            <React.Fragment>
                
                <Post shortname='gregot' sidebar={<ProSidebar toggled="false" id="portfolio-sidebar" style={this.state.scrollPos < 600 ? {"margin-left":"-12%"} : {}}>
                        <Menu iconShape="square">
                            <MenuItem><a href="#character">Character</a></MenuItem>
                            <MenuItem><a href="#environment">Environment</a></MenuItem>
                            <MenuItem><a href="#animation">Animation</a></MenuItem>
                            <MenuItem><a href="#outcome">Final Outcome</a></MenuItem>
                        </Menu>
                    </ProSidebar>}>
                    
                    {/* For playing videos */}
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.video} youtube={{start:this.state.videoStart}} onClose={() => this.setState({isOpen: false})} />
                        </div>
                    </div>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p className="subtitle">This project was done as part of Carnegie Mellon University's Intro to 3D class.</p>
                        <br id="character"/> <br/>
                        <h1>Character</h1>
                        <p className="subtitle">This piece features a psychedelic cockroach-man named Gregot, inhabiting a hostile alien landscape.</p>
                        <p className="subtitle">Eventually I just hopped into zbrush and sculpted this.</p>
                        <Image src="\assets\images\portfolio\gregot\zbrush.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p className="subtitle">I then retopologized in maya.</p>
                        <Image src="\assets\images\portfolio\gregot\retoppo.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p className="subtitle">I textured in substance painter, before re-importing into maya for a test render.</p>
                        <Image src="\assets\images\portfolio\gregot\earlyRender.jpg" alt="Portfolio Images" style={{width:"100%"}}/>
                        
                        <br id="environment"/> <br/>
                        <h1>Environment</h1>
                        <p className="subtitle">Wanting to learn Houdini, I challenged myself to generate a cool psychedelic environment for Gregot to traverse.</p>
                        <p className="subtitle">First I used Blender to generate a hill terrain which I flattened some parts of for Gregot to rest. I used Substance Painter to create a map of the areas I didn't want to extrude or place things on, so Gregot could walk relatively unobstructed.</p>
                        <Image src="\assets\images\portfolio\gregot\blender.png" alt="Portfolio Images" style={{width:"50%"}}/>
                        <Image src="\assets\images\portfolio\gregot\flatTerrain.png" alt="Portfolio Images" style={{width:"50%"}}/>
                        <p className="subtitle">Then in Houdini, I used an attributeVOP node with this map to add some cool noise to the geometry and color.</p>
                        <Image src="\assets\images\portfolio\gregot\groundAugment.png" alt="Portfolio Images" style={{width:"50%"}}/>
                        <Image src="\assets\images\portfolio\gregot\groundAugmentGraph.png" alt="Portfolio Images" style={{width:"50%"}}/>
                        <p className="subtitle">Rocks, trees and geysers were all created by scattering points along the initial terrain.</p>
                        <Image src="\assets\images\portfolio\gregot\scatter.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p className="subtitle">The rocks used similar logic to the ground, just appled to a sphere.</p>
                        <Image src="\assets\images\portfolio\gregot\rock.png" alt="Portfolio Images" style={{width:"60%"}}/>
                        <Image src="\assets\images\portfolio\gregot\rockLogic.png" alt="Portfolio Images" style={{width:"40%"}}/>
                        <p className="subtitle">Geyser's were formed through operations on 3 cylinders. They were then colored through a similar method to the ground and rocks.</p>
                        <Image src="\assets\images\portfolio\gregot\geyser.png" alt="Portfolio Images" style={{width:"70%"}}/>
                        <Image src="\assets\images\portfolio\gregot\geyserLogic.png" alt="Portfolio Images" style={{width:"30%"}}/>
                        <p className="subtitle">Trees were made with Houdini's Labs Tree Generators. I adjusted some settings to get things looking sufficiently brooding and psychedelic.</p>
                        <Image src="\assets\images\portfolio\gregot\tree.png" alt="Portfolio Images" style={{width:"70%"}}/>
                        <Image src="\assets\images\portfolio\gregot\treeLogic.png" alt="Portfolio Images" style={{width:"30%"}}/>
                        <p className="subtitle">Putting it all together I was pretty happy with the final look.</p>
                        <Image src="\assets\images\portfolio\gregot\houdiniEarly.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p className="subtitle">I adjusted some things to make it more random then did some test-renders in maya.</p>
                        <Image src="\assets\images\portfolio\gregot\houdiniTestRender.jpg" alt="Portfolio Images" style={{width:"50%"}}/>
                        <Image src="\assets\images\portfolio\gregot\houdiniTestRender3.jpg" alt="Portfolio Images" style={{width:"50%"}}/>
                        <Image src="\assets\images\portfolio\gregot\houdiniTestRender2.jpg" alt="Portfolio Images" style={{width:"100%"}}/>
                        
                        <br id="animation"/> <br/>
                        <h1>Animation</h1>
                        <p className="subtitle">I rigged Gregot in Maya, using IKs and look constraints where I deemed appropriate.</p>
                        <Image src="\assets\images\portfolio\gregot\rigEarly.png" alt="Portfolio Images" style={{width:"50%"}}/>
                        <Image src="\assets\images\portfolio\gregot\rigPose.png" alt="Portfolio Images" style={{width:"50%"}}/>
                        <Image src="\assets\images\portfolio\gregot\rigEnvironment.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p>By the time I finished weight painting I noticed some of his joints weren't well positioned, but there was no longer time to adjust. I've learned some valuable lessons.</p>
                        <p className="subtitle">I created blendshape controls for his eyes and mouth, and used correctives to cover up some of my rigging mistakes.</p>
                        <Image src="\assets\images\portfolio\gregot\blendshapes.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p className="subtitle">I did an early animation test to block everything out.</p>

                        <div className="thumb position-relative mb--30">
                            <img src="\assets\images\portfolio\gregot\animTestThumb.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <button className="video-popup position-top-center" onClick={() => this.openModal('VXE3ie0QKi4')}><span className="play-icon"></span></button>
                        </div>

                        <p className="subtitle">I then wrote some custom scripts for his wrist-twist and the secondary motion of his antennas. I used some post-processing for bloom and denoising, and used a MASH network for the particle system.</p>


                        <br id="outcome"/> <br/>
                        <h1>Outcome</h1>
                        <p className="subtitle">
                            <br/><b>Final Submission: </b>
                        </p>
                            <div className="thumb position-relative mb--30">
                                <img src="\assets\images\portfolio\gregot\climbThumb.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                                <button className="video-popup position-top-center" onClick={() => this.openModal('CoRwO9Jux0E')}><span className="play-icon"></span></button>
                            </div>
                        <p className="subtitle">
                            <br/><b>Planned Changes and Hindsight: </b>
                            <p className="subtitle">Many artifacts appeared when moving from Houdini to Maya. This is partially because of how Houdini unwraps, as it doesn't project beyond the boundaries of the seams like Substance Painter does, resulting in bad artifacts for large objects with low texture resolutions. I should have generated 3 or 4 varieties of each object (tree, rock, geyser) and scattered them in different sizes and orientations, so I wouldn't need as much texture space when unwrapping.</p>
                            <p className="subtitle">My rig contains IKs for the legs, but not hands, which would have saved me a lot of time while animating his climbing.</p>
                            <p className="subtitle">I should have separated the rope around his pants from the legs themselves, as not doing this resulted in some broken deformations (which I covered up slightly with correctives).</p>
                        </p>


                        
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default GregotClimb;
