import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import {webMFormattedSimple} from "../../helpers/resourceFinder";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {Grid, Image} from 'semantic-ui-react';
import 'react-pro-sidebar/dist/css/styles.css';

class DrSpoof extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          scrollPos: 0,
          video: '',
          videoStart: 0,
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {      
        this.setState({
            scrollPos: window.scrollY,
        })
    }

    openModal (videoURL, start = 0) {
        this.setState({isOpen: true, video:videoURL, videoStart:start})
    }
    render(){
        return(
            <React.Fragment>
                
                <Post shortname='drSpoof' sidebar={<ProSidebar toggled="false" id="portfolio-sidebar" style={this.state.scrollPos < 600 ? {"margin-left":"-12%"} : {}}>
                        <Menu iconShape="square">
                        <MenuItem><a href="#desc">Development</a></MenuItem>
                        <MenuItem><a href="#game">Final Game</a></MenuItem>
                        </Menu>
                    </ProSidebar>}>
                    
                    {/* For playing videos */}
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.video} youtube={{start:this.state.videoStart}} onClose={() => this.setState({isOpen: false})} />
                        </div>
                    </div>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p>This was done for Round 2 of Carnegie Mellon University's Building Virtual Worlds course.</p>

                        <br id="desc"/>
                        <p className="subtitle">
                            <br/><b>Platform:</b> Oculus Quest 2.
                            <br/><b>Timeframe:</b> Two weeks.
                            <br/><b>Teammates:</b> Xiaoying Wang (other programmer), Yi Hsiu Huang (artist), Wenbo Guo (artist), Gillian Ryan (sound designer).
                        </p>
                        <p className="subtitle">We wanted to design an escape-room experience, set in an evil lab. We didn’t want to count on someone new to VR being comfortable with movement controls, so the whole experience would be from behind a desk. We settled on color mixing as the primary puzzle mechanic, in addition to a laser system.</p>
                        <p className="subtitle">I built the color mixing System, as well as general VR controls such as grabbing test tubes and pressing buttons. I also made sure the player’s hand would change to reflect the desired actions of that location, such as pointing near pressable buttons and curling when the grip button is pressed, to provide psychological feedback. I animated this in blender.</p>
                        <p className="subtitle">I also worked on several cool effects. Pink, volumetric lighting in the room, green floor fog, having parts of the laser spin when fired, particle effects for the laser charge, explosion effects, invisible ink shaders etc.</p>
                        <p className="subtitle">Here’s an early iteration of the fluid drip mechanic I developed.</p>
                        <img src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2FFluidsTest1.gif?alt=media&token=1eb0a936-c8c4-4d44-808a-5c22c098a276" alt="Portfolio Images"/>
                        <p className="subtitle">The invisible ink also initially revealed its contents directly under green ink (as visible in the slightly buggy early prototype gif below), however this was changed to just a generally growing texture as playtesters found aiming the goop too difficult.</p>
                        <img src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2FFluidsTest2.gif?alt=media&token=1eb0a936-c8c4-4d44-808a-5c22c098a276" alt="Portfolio Images"/>
                        <p className="subtitle">We joked about “what happens if you fire the laser in the wrong place”. We thought it might be funny if it tore through the lab, then someone thought maybe it should fire out the window and lead the player to accidentally destroy a city. We all laughed at this and implemented into the game. I only found out after submission that the city modelled was the Pittsburgh skyline… </p>
                        <p className="subtitle">Fortunately, our naive tester found it funny. Here’s her entire playthrough, as well as some helpful feedback from the faculty.</p>
                            <div className="thumb position-relative mb--30">
                                <img src="\assets\images\portfolio\naiveTestThumb.PNG" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                                <button className="video-popup position-top-center" onClick={() => this.openModal('HpBdYXW53l4', 2188)}><span className="play-icon"></span></button>
                            </div>
                        <br/><br/>
                        <br id="game"/><br/><br/><br/>
                        <h2>Final Game</h2>
                        <Image src="\assets\images\portfolio\Team27_Coverphoto.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p className="subtitle">
                            <br/><b>Gameplay video: </b>
                        </p>
                            <div className="thumb position-relative mb--30">
                                <img src="\assets\images\portfolio\SpoofThumb.PNG" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                                <button className="video-popup position-top-center" onClick={() => this.openModal('ydqFpZr_8Mk')}><span className="play-icon"></span></button>
                            </div>
                        <br/><br/>

                        
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default DrSpoof;
