import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import {webMFormattedSimple} from "../../helpers/resourceFinder";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {Grid, Image} from 'semantic-ui-react';
import 'react-pro-sidebar/dist/css/styles.css';

class MakingConnections extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          scrollPos: 0,
          video: '',
          videoStart: 0,
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {      
        this.setState({
            scrollPos: window.scrollY,
        })
    }

    openModal (videoURL, start = 0) {
        this.setState({isOpen: true, video:videoURL, videoStart:start})
    }
    render(){
        return(
            <React.Fragment>
                
                <Post shortname='makingConnections' sidebar={<ProSidebar toggled="false" id="portfolio-sidebar" style={this.state.scrollPos < 600 ? {"margin-left":"-12%"} : {}}>
                        <Menu iconShape="square">
                            <MenuItem><a href="#desc">Development</a></MenuItem>
                            <MenuItem><a href="#game">Final Game</a></MenuItem>
                        </Menu>
                    </ProSidebar>}>
                    
                    {/* For playing videos */}
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.video} youtube={{start:this.state.videoStart}} onClose={() => this.setState({isOpen: false})} />
                        </div>
                    </div>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p>This was done for Round 1 of Carnegie Mellon University's Building Virtual Worlds course.</p>

                        <br id="desc"/>
                        <p className="subtitle">
                            <br/><b>Platform:</b> Microsoft HoloLens 2.
                            <br/><b>Timeframe:</b> Two weeks.
                            <br/><b>Teammates:</b> Jia Du (other programmer), <a href="https://www.elizaye.com/">Yongzi Ye (artist)</a>, <a href="https://emilytyminski.wixsite.com/portfolio">Emily Tyminski (artist)</a>, Tiange Zhou (sound designer).
                        </p>
                        <p className="subtitle">Starting this project none of us had never worked with Hololens, however we were able to make this simple game within two weeks.</p>
                        <p className="subtitle">This was a game about a boy who’s trying to deliver a love letter to a girl, but he’s running away from the embodiment of his anxiety. </p>
                        <p className="subtitle">Early on I made trail drawing mechanics and effects and started experimenting with how this could work in terms of gameplay mechanics. Here’s an early mirror idea I probably copied from a thousand flash games.</p>
                        <div className="thumb position-relative mb--30">
                            <img src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2FmakingConnectionsMirror.gif?alt=media&token=a11471bb-c81c-4230-9058-b67bcb6b5913" alt="Portfolio Images"/>
                        </div>
                        <p className="subtitle">Eventually we rolled with this.</p>
                        <Image src="\assets\images\portfolio\EmIdea.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p className="subtitle">We talked about having negative/anxious messages exude from the dark follower, so I prototyped the follower and the messages.</p>
                        <div className="thumb position-relative mb--30">
                            <img src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2FnegativityBubbles.gif?alt=media&token=79b30f09-a665-42d8-8055-7be20c137623" alt="Portfolio Images"/>
                        </div>
                        <p className="subtitle">We continue like this for some time. The level expands, Eliza creates awesome environment features, Tiange works on the soundtrack, Lena has got the emulator properly working, Em has developed an intro cutscene explaining story and I combine it all together and get it working with the hololens emulator interface. (Side note: thank god I familiarized myself with Unity's pathfinding in round 0, really helped code-wise).</p>
                        <p className="subtitle">One week in, we had this to show.</p>
                        <div className="thumb position-relative mb--30">
                            <img src="\assets\images\portfolio\makingConnectionsInterimThumbnail.PNG" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <button className="video-popup position-top-center" onClick={() => this.openModal('Rqsv7_cf0As')}><span className="play-icon"></span></button>
                        </div>
                        <br/><br/>
                        <p className="subtitle">Working towards the final submission, we added more obstacles, had positive messages from the girl play a role and placed ending scene with a beautifully renditioned original song by to reward completion. Unfortunately the other programmer got sick this final week, but she provided me all the required resources on what she was working on and I was perfectly positioned to implement the final mechanics alone. Jumping over the bridge was interesting, as I decided to do the parabola of the jump through recalling my projectile motion equations from high school. </p>
                        <p className="subtitle">Once I finished coding the base mechanics, I got to dabble in art and animation, animating all the boy’s movement in blender (walk cycles, jumping and his depressed head shake), created shaders for the water under the bridge, added particle effects for the floating shadow bubbles which grow in intensity if the player is consumed by darkness, made love heart particles for victory and made the trail look cooler. The rest of my team was contributing incredible 2D art, environment and sound design, and we completed our project well in time for the final deadline.</p>
                        <p className="subtitle">Reflecting on my team, it was a highly encouraging space. I would greatly welcome collaborating once more with any of my other former teammates.</p>
                        <br id="game"/><br/><br/><br/>
                        <h2>Making Connections</h2>
                        <p className="subtitle">
                            <br/><b>Gameplay video: </b>(unedited footage)
                        </p>
                        <div className="thumb position-relative mb--30">
                            <img src="\assets\images\portfolio\makingConnectionsVidThumb.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <button className="video-popup position-top-center" onClick={() => this.openModal('p1ZuIH1KU0k')}><span className="play-icon"></span></button>
                        </div>
                        
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default MakingConnections;
