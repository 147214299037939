import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import {backgroundGif} from "../elements/helpers/resourceFinder";
import {projectList, projectListOf} from "../elements/helpers/projects";
import BlogContent from '../elements/blog/BlogContent';

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        description: '',// Place desired text here
        buttonText: '',
        buttonLink: ''
    }
]
const PortfolioLanding = () => {
    let title = 'About Me',
        description = 'I\'m a game programmer and technical artist passionate about games. I love creating complex and vibrant effects and environments through the fusion of art and code! I\'m also learning everything I can about game design on the side, and I hope you follow me on my adventures! I\'m set to attain my Master of Entertainment Technology from Carnegie Mellon University\'s ETC in the first half of 2023.';
    const PostList = BlogContent.slice(0 , 3);
    return (
        <div className="active-dark">
            <Helmet pageTitle="Faris' Site" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper" style={{"height":"max(70vw,500px)"}}>
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center" key={index}>
                            <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center" key={index} style={{"opacity":"0.25", "height":"100%", "width":"max(100%,700px)", "position":"absolute"}}>
                                <video loop autoPlay muted style={{"width":"100%"}}>
                                    <source src={backgroundGif(1)}/>
                                </video>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title">Hi, I'm <a href="/#about" style={{"color":"DarkSeaGreen"}}>Faris</a> <br/> </h1>
                                            <h2>
                                            <span style={{"whiteSpace":"normal", "color":"DodgerBlue"}}>Game Programmer</span> / <span style={{"whiteSpace":"normal", "color":"MediumOrchid"}}>Technical Artist</span>
                                            </h2>
                                            <h4>Welcome to my digital portfolio. </h4>{/**Welcome to my digital resumeportfoliblog. */}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/Faris-2.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row mt--30">
                                <TabTwo tabStyle="tab-style--1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            {/* Start Portfolio Area */}
            <div id="portfolio_programming" className="fix">
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Programming</h2>
                                        <p>Projects where I wrote a lot of code!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList imgOpacity="0.8" styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" list={projectListOf('programming').slice(0 , 6)} />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/softwarePortfolio"><span>View More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start Portfolio Area */}
            <div id="portfolio_technical_art" className="fix">
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Technical Art</h2>
                                        <p>Projects where I made some pretty things!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList imgOpacity="0.8" styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" list={projectListOf('technical art').slice(0 , 6)} />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/technicalArtPortfolio"><span>View More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start Blog Area */}
            {/*{<div id="blog" className="fix">
                <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <h2>Latest Posts</h2>
                                  <p>Blog comming soon! {/*A blog dedicated to some of my thoughts on Game Design and my personal growth!*/}{/*</p>
                                </div>
                            </div>
                        </div>
                        
                        {/*<div className="row mt--60 mt_sm--40">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>*/}{/*}
                            
                    </div>    
                </div>
            </div>
            /* End Blog Area */}

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/about/about-9.jpg" contactTitle="Contact me!" />
                </div>
            </div>
            {/* End COntact Area */}

            {/*<FooterTwo />*/}
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}

export default PortfolioLanding;
