import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import {webMFormattedSimple} from "../../helpers/resourceFinder";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {Grid, Image} from 'semantic-ui-react';
import 'react-pro-sidebar/dist/css/styles.css';

class SpaceRPS extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          scrollPos: 0,
          video: '',
          videoStart: 0,
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {      
        this.setState({
            scrollPos: window.scrollY,
        })
    }

    openModal (videoURL, start = 0) {
        this.setState({isOpen: true, video:videoURL, videoStart:start})
    }
    render(){
        return(
            <React.Fragment>
                
                <Post shortname='spaceRPS' sidebar={<ProSidebar toggled="false" id="portfolio-sidebar" style={this.state.scrollPos < 600 ? {"margin-left":"-12%"} : {}}>
                        <Menu iconShape="square">
                        <MenuItem><a href="#desc">Development</a></MenuItem>
                        <MenuItem><a href="#game">Final Game</a></MenuItem>
                        </Menu>
                    </ProSidebar>}>
                    
                    {/* For playing videos */}
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.video} youtube={{start:this.state.videoStart}} onClose={() => this.setState({isOpen: false})} />
                        </div>
                    </div>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p>This was done for Round 3 of Carnegie Mellon University's Building Virtual Worlds course.</p>
                        <br id="desc"/>
                        <p className="subtitle">
                            <br/><b>Platform:</b> Oculus Quest 2 with Experimental Hand Tracking.
                            <br/><b>Timeframe:</b> One week.
                            <br/><b>Teammates:</b> Dian Yi (artist), Chang Liu (artist), Yutian Xie (sound designer).
                            <br/>This round, I was a solo programmer.
                        </p>
                        <p className="subtitle">I built this fragment shader for the rhythm trails in ShaderGraph.</p>
                        <img src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2FPaths.gif?alt=media&token=1eb0a936-c8c4-4d44-808a-5c22c098a276" alt="Portfolio Images"/>
                        <p className="subtitle">I also wanted the lines on the path to wiggle when a note is correctly hit.</p>
                        <img style={{"margin-left":"auto","margin-right":"auto", "display":"block", "width":"50%"}} src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2Fwiggle.gif?alt=media&token=1eb0a936-c8c4-4d44-808a-5c22c098a276" alt="Portfolio Images"/>
                        <p className="subtitle">I coded each gesture by calculating the distances between each finger’s pointer and the hand’s palm, which seemed to work well enough. We color coded the aliens and gestures to make gameplay more intuitive. As I built UI, I would take screenshots and circle elements which Ivy would then replace with proper PNGs.</p>
                        <Grid columns={2}>
                            <Grid.Row verticalAlign='middle' style={{"flex-wrap":"nowrap"}}> 
                                <Grid.Column style={{width:"50%"}}>
                                    <Image src="\assets\images\portfolio\UIBlanks.png" alt="Portfolio Images" style={{width:"100%"}}/>
                                </Grid.Column>
                                <Grid.Column style={{width:"50%"}}>
                                    <Image src="\assets\images\portfolio\UIBlanks2.png" alt="Portfolio Images" style={{width:"100%"}}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <p className="subtitle">I built an interface for writing songs, which I then adapted to a notepad-file system which looked similar to guitar tabs. The program would ask you for BPM and time signature, and then generate the gestures in game for you. I sent this out to the song creation instructions for my program to the rest of the class, since I wanted free content.</p>
                        <Image src="\assets\images\portfolio\RPSSongCreation.png" alt="Portfolio Images" style={{width:"100%"}}/>

                        <p className="subtitle">
                                <b>Festival Adjustments:</b>
                        </p>
                        <p className="subtitle">This game made it into ETC Festival, so I made several modifications. We expanded the number of songs, and I connected it to an online leaderboard system, which I hosted on a Firebase database. This also involved creating a login system in VR.  I also built a website for viewing first, second and third place, so that you could track if your score’s been beaten on your phone in real time throughout the night. The URL might be down by the time this is posted, so I’ll include a gif of the current site.</p>
                        <div className="portfolio-thumb-inner">
                            <div className="thumb position-relative mb--30">
                                {webMFormattedSimple("SpaceRPSLeaderboard", {height:"400px"})}
                                <div style={{'height':'400px'}}/>
                            </div>
                        </div>
                        <p className="subtitle">Congrats to Ryan for winning the Lego set!</p>

                        <br/><br id="game"/><br/><br/>
                        <h2>Space RPS</h2>
                        <Image src="\assets\images\portfolio\spaceRPSCover.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p className="subtitle">Come final submission, I presented the game in front of the class. <a href="https://fb.watch/9Tkt8WNZA8/">This footage got uploaded to the ETC’s Facebook page.</a></p>
                        <p className="subtitle">
                            <br/><b>Gameplay video: </b>
                        </p>
                            <div className="thumb position-relative mb--30">
                                <img src="\assets\images\portfolio\spaceRPSThumb.PNG" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                                <button className="video-popup position-top-center" onClick={() => this.openModal('B93nNloCRGs')}><span className="play-icon"></span></button>
                            </div>
                        <br/><br/>


                        
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default SpaceRPS;
