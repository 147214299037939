import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import YouTube from 'react-youtube';

import 'react-pro-sidebar/dist/css/styles.css';

class Ecotopia extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          scrollPos: 0,
          video: '',
          videoStart: 0,
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {      
        this.setState({
            scrollPos: window.scrollY,
        })
    }

    openModal (videoURL, start = 0) {
        this.setState({isOpen: true, video:videoURL, videoStart:start})
    }
    render(){
        return(
            <React.Fragment>
                
                <Post shortname='ecotopia'>
                    
                    {/* For playing videos */}
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.video} youtube={{start:this.state.videoStart}} onClose={() => this.setState({isOpen: false})} />
                        </div>
                    </div>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p className="subtitle">I had the honor of being the Game Designer and sole programmer on a 6 person team for a semester while we worked on our hopeful, eco-futurist game, <i>Project Ecotopia</i>!</p>
                        <p className="subtitle">Our game is available for download <a href="https://future-forgers.itch.io/project-ecotopia">here!!!</a></p>
                        <YouTube videoId={'F01BxGrXwQY'} iframeClassName={'youtubeIframe'}/>

                        <h1>My role</h1>
                        <p className="subtitle">This one was a doozie! As the only programmer on this project, I was responsible for building the core game/event systems, animation/character gesture system (and binding it flexibly to the dialogue systems), UI implementation, all game controls/mechanics and coding of the puzzles. I did this all in Unity C#. </p>
                        <p className="subtitle">Ultimately, I'm grateful I got to work with such a passionate and talented team. They were truly one-of-a-kind :)</p>
                        <p className="subtitle">You can find our project website <a href="https://projects.etc.cmu.edu/future-forgers/">here</a> which includes our dev blog. And below you'll find a full walkthrough of the game.</p>
                        <YouTube videoId={'qDGkKmiEKHg'} iframeClassName={'youtubeIframe'}/>
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default Ecotopia;
