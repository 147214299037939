import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import { Document, Page, pdfjs } from 'react-pdf';
import throttle from "lodash.throttle"
import Post from "./Templates/post";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Hexit extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          numPages: null,
          pageNumber: 1,
          width: null
          
        }
        this.throttledSetDivSize = throttle(this.setDivSize, 500)
        this.openModal = this.openModal.bind(this)
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    onDocumentLoadSuccess({ nPages }) {
        console.log(nPages);
        this.setState({numPages: nPages});
    }
    componentDidMount () {
        this.setDivSize()
        window.addEventListener("resize", this.throttledSetDivSize)
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.throttledSetDivSize)
    }

    setDivSize = () => {
        this.setState({width: this.pdfWrapper.getBoundingClientRect().width})
    }
    render(){
        return(
            <React.Fragment>
                <Post shortname='hexit'>
                    <div className="inner">
                        <h2>What was it?</h2>
                        <p className="subtitle">Hexit was a short musical puzzle-platformer developed by Alexandar Cock, Aben Drake and myself for the University of Melbourne's Graphics and Interaction subject (COMP30019). </p>
                        <p>I programmed the game's shaders directly in HLSL, and developed the camera motion and many level creation helper tools in C#. I also designed the evil orange crystals using procedurally-generated koch fractals, and enabled them to jiggle with the music. </p>
                        <br/><br/>
                        <h2>Can I see it?</h2>
                        <p className="subtitle">Sure! The royalty-free music version of it can be downloaded <a href="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/zips%2FHexit.zip?alt=media&token=50095834-a011-4536-80da-f4a854fbc7c5">here</a>.</p>
                        <p>Or you could just watch the walkthrough below.</p>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src="/assets/images/portfolio/hexitThumbnail.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='r-JK8M3Jd2g' onClose={() => this.setState({isOpen: false})} />
                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                        </div>
                        <br/><br/>
                    </div>
                    <div className="inner">
                        <h2>Tell us about the shaders you made!</h2>
                        <p className="subtitle">Stop yelling at me! As part of the project report, I had to describe each of the shaders and how I made them. I'll copy-paste that section here for your convenience.</p>
                    </div>
                    <div id="pdfWrapper" style={{width: "100%"}} ref={(ref) => this.pdfWrapper = ref}>
                        <Document
                                file={"https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/pdfs%2Fhexit-report.pdf?alt=media&token=17bdde3d-1b2e-4f37-af42-d30a14b40eba"}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                                <Page pageNumber={1} width={this.state.width}/>
                                <Page pageNumber={2} width={this.state.width}/>
                                <Page pageNumber={3} width={this.state.width}/>
                                <Page pageNumber={4} width={this.state.width}/>
                                <Page pageNumber={5} width={this.state.width}/>
                            </Document>
                    </div>
                </Post>
            </React.Fragment>
        )
    }
}
export default Hexit;
