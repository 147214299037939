import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import PageHelmet from "../../../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../../../component/header/HeaderThree";
import {Image} from 'semantic-ui-react';

class BlogPost extends Component  {
    render(){
        //const project = findProject(this.props.shortname);
        /*if (project==null){
            // Go to 404 page
            return <Redirect to="/404"/>;
        }else*/
            return(
                <React.Fragment>
                    {<PageHelmet pageTitle={this.props.title}/>}
                    <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/> 

                    {/* Start Breadcrump Area  */}
                    <div className="rn-page-title-area pt--20 pb--20"  data-black-overlay="1">
                        <Image src="\assets\images\blog\bkg_0.jpg" alt="Blog Images" style={{width:"100%", height:"110%", "margin-top":"-20px", "position":"absolute"}}/>

                        
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="rn-page-title text-center pt--100">
                                        <h2 className="title">{this.props.title}</h2>
                                        <p style={{color:"black"}}>{this.props.date}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrump Area */}

                    {/* sidebar if present }
                    {this.props.sidebar}

                    {/* Start Portfolio Details */}
                    <div className="rn-portfolio-details pt--30 ptb--30 bg_color--1" style={{"z-index":"5","position":"relative"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="portfolio-details">
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Portfolio Details */}

                    {/* Start Related Work 
                    <RelatedWork shortname={project.shortname}/>
                    Start Related Work */}

                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}
                    


                </React.Fragment>
            )
    }
}

export default BlogPost;