import React, { Component } from "react";
import StoryText from "./StoryText";
import SlowType from "../SlowType";
import StoryAwaitOptionSelect from "./StoryAwaitOptionSelect";
import StoryOption from "./StoryOption";
import StoryReveal from "./StoryReveal";
import StoryClearAll from "./StoryClearAll";
import {Grid, Image} from 'semantic-ui-react';
import StoryDramaticReveal from "./StoryDramaticReveal";
import StoryAwait from "./StoryAwait";

class Story extends Component{
    constructor(props) {
        super(props);
        if (this.props.completionState== null){
            this.state = {
                storyStartOrigin: Date.now(),
                selectedOptions: [],
                optionSelectionTimes : [],
                childrenCount: 0,
                completed: false,
                restarted: false,
            }
        }else{
            this.state = {
                storyStartOrigin: this.props.completionState.storyStartOrigin,
                selectedOptions: this.props.completionState.selectedOptions,
                optionSelectionTimes : this.props.completionState.optionSelectionTimes,
                childrenCount: this.props.completionState.childrenCount,
                completed: this.props.completionState.completed,
                restarted: false,
            }
        }

        this.restart = this.restart.bind(this);
    }

    makeSelection(idx){
        this.setState({
            selectedOptions: [...this.state.selectedOptions, idx],
            optionSelectionTimes : [...this.state.optionSelectionTimes, Date.now()],
        })
    }
    
    restart(){
        this.setState({
            storyStartOrigin: Date.now(),
            selectedOptions: [],
            optionSelectionTimes : [],
            childrenCount: 0,
            completed: false,
            restarted: true,
        })
    }

    stateCleaned(){
        return {
            storyStartOrigin: this.state.storyStartOrigin,
            selectedOptions: this.state.selectedOptions,
            optionSelectionTimes : this.state.optionSelectionTimes,
            childrenCount: this.state.childrenCount,
            completed: this.state.completed,
            restarted: this.state.restarted,
        }
    }

    componentDidMount() {

    }
    componentWillUnmount() {
        
    }

    buildChildrenAndScroll(){
        var childrenList = this.buildChildren();
        if (childrenList.length != this.state.childrenCount){
            this.setState({childrenCount:childrenList.length});
            setTimeout(function() { 
                if (this.screenBottomStory != null)
                    this.screenBottomStory.scrollIntoView({behavior:"smooth"}); 
                }, 300);
                
        }
        return childrenList
    }

    buildChildren(){
        var newChildren = [];
        var elementStartTime = this.props.startTime;
        var decisionsMade = 0;

        // The queue of which elements to expand
        var expansionQueue = this.props.children.slice(0, this.props.children.length);

        for (var i = 0; i < expansionQueue.length; i++){
            var child = expansionQueue[i];
            if (child.type === StoryText){
                var typeDuration = child.props.children.length / this.props.typeSpeed;
                newChildren.push(
                    <p className="subtitle">
                        <SlowType color1={this.props.color1} color2={this.props.color2} startTime={elementStartTime} typeTime={typeDuration} pauseAndErase={false} timelineOrigin={this.state.storyStartOrigin}>{child.props.children}</SlowType>
                    </p>
                );
                elementStartTime += typeDuration + this.props.timeBetweenLines;

                // If this piece of text is still expanding, don't continue to whatever's next
                if (elementStartTime*1000 > Date.now() - this.state.storyStartOrigin)
                    return newChildren;

            }else if (child.type === StoryAwaitOptionSelect){
                // Check if this option has already been made
                if (decisionsMade < this.state.selectedOptions.length){
                    var selectedOption = this.props.children.length > 1 ?
                        child.props.children[this.state.selectedOptions[decisionsMade]]
                        : child.props.children;
                    elementStartTime = (this.state.optionSelectionTimes[decisionsMade] - this.state.storyStartOrigin)/1000;
                    // Expand the option at this location and continue
                    if (selectedOption!=null){
                        if (selectedOption.props.children != null){
                            if (selectedOption.props.children.length > 1){
                                for (var j = selectedOption.props.children.length - 1; j >= 0; j--)
                                    expansionQueue.splice(i+1, 0, selectedOption.props.children[j]);
                            }else
                                expansionQueue.splice(i+1, 0, selectedOption.props.children);
                        }
                    }
                    decisionsMade = decisionsMade + 1;
                }else{ // Present all the options to the player and halt
                    newChildren.push(
                        <Grid columns={child.props.children.length}>
                            <Grid.Row verticalAlign='middle'>
                                {
                                    child.props.children.length > 1 ? (
                                        child.props.children.map((storyOption, index) => 
                                        <Grid.Column style={{width:"250px", "marginLeft":"auto", "marginRight":"auto"}}>
                                            <button onClick={() => this.makeSelection(index)} className="buttonCool">{storyOption.props.title}</button>
                                        </Grid.Column>))
                                    :
                                    <Grid.Column style={{width:"250px", "marginLeft":"auto", "marginRight":"auto"}}>
                                        <button onClick={() => this.makeSelection(0)} className="buttonCool">{child.props.children.props.title}</button>
                                    </Grid.Column>
                                }
                            </Grid.Row>
                        </Grid>
                    )

                    return newChildren;
                }
            }else if (child.type === StoryClearAll){
                // Re-render all the previous stuff, with only the text paragraphs and have them self-erase.
                var erasedArray = [];
                newChildren.forEach(childElement => {
                    if (childElement.type.toString() == "p"){
                        if (childElement.props.children.type == SlowType){
                            var eraseTimeLength = childElement.props.children.props.children.length / (this.props.typeSpeed * 3);

                            erasedArray.push(
                                <p className="subtitle">
                                    <SlowType color1={this.props.color1} color2={this.props.color2} startTime={0} typeTime={0} pauseAndErase={true} pauseTime={elementStartTime} eraseTime={eraseTimeLength} timelineOrigin={this.state.storyStartOrigin}>
                                        {childElement.props.children.props.children}
                                    </SlowType>
                                </p>
                            );

                            elementStartTime = elementStartTime + eraseTimeLength;
                        }
                    }
                });
                
                // Once the text paragraphs have erased, empty the newChildren array and continue forth
                if (elementStartTime*1000 > Date.now() - this.state.storyStartOrigin)
                    newChildren = erasedArray;
                else
                    newChildren = [];
            }else if (child.type === StoryReveal){
                newChildren.push(child.props.children);
            }else if (child.type === StoryDramaticReveal){
                
                var typeDuration2 = (child.props.reveal.length * 18 / this.props.typeSpeed);
                var childrenStr = "";
                var typeDuration1 = 0;
                if (child.props.children != null){
                    typeDuration1 = (child.props.children.length * 3/ this.props.typeSpeed);
                    childrenStr = child.props.children;
                }

                newChildren.push(
                    <p className="subtitle">
                        <SlowType color1={this.props.color1} color2={this.props.color2} startTime={elementStartTime}  typeTime={typeDuration1} pauseAndErase={false} timelineOrigin={this.state.storyStartOrigin}>{childrenStr}</SlowType><SlowType color1={this.props.color1} color2={this.props.color2} startTime={elementStartTime + typeDuration1}  typeTime={typeDuration2} pauseAndErase={false} timelineOrigin={this.state.storyStartOrigin}>{child.props.reveal}</SlowType>
                    </p>
                );

                elementStartTime += typeDuration1 + typeDuration2 + this.props.timeBetweenLines;

                // If this piece of text is still expanding, don't continue to whatever's next
                if (elementStartTime*1000 > Date.now() - this.state.storyStartOrigin)
                    return newChildren;


            }else if (child.type === StoryAwait){
                elementStartTime += child.props.duration;

                // If the time is still passing, don't continue to whatever's next
                if (elementStartTime*1000 > Date.now() - this.state.storyStartOrigin)
                    return newChildren;
            }
            
        }

        // We've unravelled the whole story
        if (!this.state.completed){
            if (this.props.onComplete!=null)
                this.props.onComplete(this.stateCleaned());
            this.setState({completed: true});
        }

        // Set the restart button
        newChildren.push(
            <button className="buttonCool" onClick={this.restart}>
                Restart?
            </button>
        );

        return newChildren;
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.buildChildrenAndScroll()
                }
                <div id="screenBottomStory" ref={(el) => { this.screenBottomStory = el; }}/>
            </React.Fragment>
        )
    }
}
export default Story;
