import React, { Component } from "react";
import {Redirect} from "react-router-dom";
import { findProject } from "../../../helpers/projects";
import { backgroundGifFormatted } from "../../../helpers/resourceFinder";
import RelatedWork from "./relatedWork";
import PageHelmet from "../../../../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../../../../component/header/HeaderThree";
import {Image} from 'semantic-ui-react';

class Post extends Component  {
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const project = findProject(this.props.shortname);
        if (project==null){
            // Go to 404 page
            return <Redirect to="/404"/>;
        }else
            return(
                <React.Fragment>
                    <PageHelmet pageTitle={project.title} />
                    <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/> 

                    {project.backgroundGif != null ? 
                    /* Start Breadcrump Area */
                    (<div className="rn-page-title-area pt--120 pb--190"  data-black-overlay="10">
                        
                            {project.backgroundGif.hasOwnProperty('viewWidth') ? 
                                backgroundGifFormatted(project.backgroundGif.id,project.backgroundGif.settings,project.backgroundGif.viewWidth) :
                                backgroundGifFormatted(project.backgroundGif.id,project.backgroundGif.settings)}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="rn-page-title text-center pt--100">
                                        <h2 className="title theme-gradient">{project.title}</h2>
                                        <p>{project.category}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                    : null }

                    {/* End Breadcrump Area */}

                    {/* sidebar if present */}
                    {this.props.sidebar}

                    {/* Start Portfolio Details */}
                    <div className="rn-portfolio-details pt--90 ptb--120 bg_color--1" style={{"z-index":"5","position":"relative"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="portfolio-details">
                                        {
                                            project.titleImg != null ?
                                            <Image src={"https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/img%2F"+project.titleImg+"?alt=media&token=251496a1-e793-4ab7-911c-1e8c85c5712a"} alt="Portfolio Images" style={{width:"100%"}}/>
                                            : null
                                        }

                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Portfolio Details */}

                    {/* Start Related Work */}
                    <RelatedWork shortname={project.shortname}/>
                    {/* Start Related Work */}

                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}
                    


                </React.Fragment>
            )
    }
}

export default Post;