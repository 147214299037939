import React, { Component } from "react";
import PageHelmet from "../../../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../../../component/header/HeaderThree";
import {Grid, Image} from 'semantic-ui-react';
import {CopyBlock, dracula} from "react-code-blocks";
import {backgroundGifFormatted} from "../../helpers/resourceFinder";
import Post from "./Templates/post";


class MiscProjects extends Component{
    requestCodeSnippet (location, item){
        const corsHeaders = new Headers();
        const myRequest = new Request(location, {
            method: 'GET',
            headers: corsHeaders,
            mode: 'cors',
            cache: 'default',
          });
        fetch (myRequest)
          .then((r) => r.text())
          .then(text => {
                switch (item){
                    case "hexGMText":
                        this.setState({
                            hexGMText: text
                        })
                        break;
                    case "extrudeShaderText":
                        this.setState({
                            extrudeShaderText: text
                        })
                        break;
                    case "hexCSText":
                        this.setState({
                            hexCSText: text
                        })
                        break;
                    case "hexGSText":
                        this.setState({
                            hexGSText: text
                        })
                        break;
                    default:
                        break;
                }
          });
    }

    gifUrl(item){
        return "https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2F"+item+".gif?alt=media";
    }

    constructor () {
        super()
        this.state = {
          isOpen: false,
          extrudeShaderText: "",
          hexGMText: "",
          hexCSText: "",
          hexGSText: ""
        }
        

        this.openModal = this.openModal.bind(this)
        // Request code snippits
        this.requestCodeSnippet('https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/codesnippits%2FExtrudeShader.shader?alt=media&token=16f70915-6468-4680-b6c3-e02a32a37f27',"extrudeShaderText");
        this.requestCodeSnippet('https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/codesnippits%2FHexagonalGroundManager.cs?alt=media&token=36a86bb2-3bad-4689-b486-64952d2f1dd1', "hexGMText");
        this.requestCodeSnippet('https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/codesnippits%2FHexagonComputeShader.compute?alt=media&token=0be23716-c328-4582-a880-ec6394eec7e7',"hexCSText");
        this.requestCodeSnippet('https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/codesnippits%2FHexagonGeometryShader.shader?alt=media&token=d70fc781-04e3-484c-aee6-99728883118e',"hexGSText");
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        
        
        return(
            <React.Fragment>
                <Post shortname='misc'>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p className="subtitle">Let's start with the cool hexagon compute/geometry shader which eventually inspired Hexit!</p>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src={this.gifUrl("hexShader")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                        </div>
                    </div>
                    <div className="inner">
                    <p>C# Script</p>
                    <CopyBlock
                        text={this.state.hexGMText === "" ? "loading..." : this.state.hexGMText}
                        customStyle={{
                            height: '200px',
                            overflow: 'scroll',
                            "margin-bottom": "30px",
                            }}
                        language={"c"}
                        showLineNumbers={true}
                        theme={dracula}
                        codeBlock
                    />
                    <p>Compute Shader</p>
                    <CopyBlock
                        text={this.state.hexCSText === "" ? "loading..." : this.state.hexCSText}
                        customStyle={{
                            height: '200px',
                            overflow: 'scroll',
                            "margin-bottom": "30px",
                            }}
                        language={"c"}
                        showLineNumbers={true}
                        theme={dracula}
                        codeBlock
                    />
                    <p>Geometry Shader</p>
                    <CopyBlock
                        text={this.state.hexGSText === "" ? "loading..." : this.state.hexGSText}
                        customStyle={{
                            height: '200px',
                            overflow: 'scroll',
                            "margin-bottom": "30px",
                            }}
                        language={"c"}
                        showLineNumbers={true}
                        theme={dracula}
                        codeBlock
                    />
                    </div>

                    <div className="inner">
                        <p>You can download the demo <a href="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/zips%2FhexagonShader.zip?alt=media&token=d308b551-2efe-4b7e-8d37-73374ad7324d">here</a></p>
                        <p className="subtitle">Here's a random collage of blender models from my early days!</p>
                        <Grid columns={3}>
                        <Grid.Row verticalAlign='middle' style={{"flex-wrap":"nowrap"}}> 
                            <Grid.Column style={{width:"123%"}}>
                                <Image src="/assets/images/blender/staff.png" alt="Portfolio Images" />
                            </Grid.Column>
                            <Grid.Column>
                                <Image src="/assets/images/blender/book.png" alt="Portfolio Images"/>
                                <Image src="/assets/images/blender/islands.png" alt="Portfolio Images"/>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src="/assets/images/blender/jail.png" alt="Portfolio Images"/>
                                <Image src="/assets/images/blender/tombstone.png" alt="Portfolio Images" />
                            </Grid.Column>
                        </Grid.Row>
                        </Grid>
                        <p className="subtitle">And a ghost character I created in blender and substance painter for VR!</p>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src="/assets/images/blender/ghost.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                        </div>
                    </div>
                    <div className="inner">
                        <p className="subtitle">And here's a geometry shader which extrudes faces based off their normal values and fills in the gaps!</p>
                    </div>
                    
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src={this.gifUrl("extrude")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                        </div>
                    </div>
                    <div className="inner">
                    <CopyBlock
                        text={this.state.extrudeShaderText === "" ? "loading..." : this.state.extrudeShaderText}
                        customStyle={{
                            height: '200px',
                            overflow: 'scroll',
                            }}
                        language={"c"}
                        showLineNumbers={true}
                        theme={dracula}
                        codeBlock
                    />
                    </div>
                    <div className="inner">
                        <p className="subtitle">I also once wanted to create a wizard tower encased in a simulated dome.</p>
                        <p>Here's a blender model for the tower.</p>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src={this.gifUrl("wizardTower")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                        </div>
                    </div>
                    <div className="inner">
                        <p>And of course what better way to show the dome is within a simulation than projecting the most satisfying shapes over a sphere, and providing some rubber-band physics?</p>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src={this.gifUrl("simDome")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                        </div>
                    </div>
                    <div className="inner">
                        <p>Better give the stars on the sphere shader some cool options.</p>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src={this.gifUrl("domeSettings")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                        </div>
                    </div>
                    <div className="inner">
                        <p>Creating the water according to <a href="https://www.youtube.com/watch?v=FbTAbOnhRcI">this</a> tutorial also gave me my first introduction to ShaderGraph.</p>
                    </div>
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <img src={this.gifUrl("water")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                        </div>
                    </div>
                    <div className="inner">
                        <p>I should note the terrain was also created in blender and substance.</p>
                        <p className="subtitle">Finally here's a spaceship hallway and corner created in blender and substance painter using a tutorial I can no longer find.</p>
                        <Grid columns={2}>
                        <Grid.Row verticalAlign='middle' style={{"flex-wrap":"nowrap"}}> 
                            <Grid.Column style={{width:"230%"}}>
                                <Image src={this.gifUrl("walking")} alt="Portfolio Images" />
                            </Grid.Column>
                            <Grid.Column>
                                <Image src="/assets/images/blender/spaceStationHallway.png" alt="Portfolio Images"/>
                                <Image src="/assets/images/blender/piece.png" alt="Portfolio Images"/>
                            </Grid.Column>
                        </Grid.Row>
                        </Grid>
                    </div>
                    {/* End Portfolio Details */}

                </Post>

            </React.Fragment>
        )
    }
}
export default MiscProjects;
