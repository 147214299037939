import React, { Component } from "react";
import PageHelmet from "../../../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../../../component/header/HeaderThree";
import {backgroundGifFormatted} from "../../helpers/resourceFinder";
import Post from "./Templates/post";


class ThisSite extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <Post shortname="thisSite">

                {/* Start Portfolio Details */}
                <div className="inner">
                    <p className="subtitle">This site was created with a ReactJS frontend and Firebase hosting. Its development involved greatly expanding on a Trydo react template found <a href="http://trydo.rainbowit.net/dark-portfolio-landing">here</a>.</p>
                    <p>Look around and explore!</p>
                </div>
                {/* End Portfolio Details */}
                

            </Post>
            </React.Fragment>
        )
    }
}
export default ThisSite;
