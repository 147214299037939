import React, { Component } from "react";
import Post from "./Templates/post";

class MagicGame extends Component{
    gifUrl(item){
        return "https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2F"+item+".gif?alt=media";
    }
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <Post shortname='magicGame'>

                {/* Start Portfolio Details */}
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">In 2020 I began development on an untitled magic-based story game. This is currently on indefinite hiatus as I move on to more exciting things. However, here are some of the early assets I developed!</p>
                        <h2>The Egg</h2>
                        <p className="subtitle">You begin the game as an alien hatching from an egg. The egg was modelled in blender, and substance painter was used to define a base map where the blue channel would be used for the shader effect.</p>
                        
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src={this.gifUrl("eggInterior")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                    </div>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">I created the shader using shadergraph.</p>
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src="\assets\images\portfolio\eggInterior2.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                    </div>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">There were other attempts.</p>
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src={this.gifUrl("eggInteriorAtt")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                    </div>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">The volumetric lighting was also created in shadergraph. I used <a href="https://www.youtube.com/watch?v=rihJzWq7sE4">this</a> tutorial to get a general idea, then made modifications more suited for VR.</p>
                        <p className="subtitle">Then the bubbles and dust particles were simply created using Krita and VFX grpah.</p>
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src="\assets\images\portfolio\vfxGraph.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                    </div>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <h2>Mortimus</h2>
                        <p className="subtitle">The player's species is termed "Mortimus". I sketched rough concept art of this using Krita.</p>
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src="\assets\images\portfolio\mortimusConcept.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                    </div>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">I then modelled this character using 3ds Max.</p>
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src="\assets\images\portfolio\maxModel.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                    </div>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">And made their material in substance painter.</p>
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src="\assets\images\portfolio\substance.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                    </div>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">Notice that their eyes are laid out for easy 2D animation, and the oval at the bottom left represents the eye at the back of the player's head which is to be overriden by a cool shader for other Mortimus characters later.</p>
                        <p className="subtitle">I've also made a map for their tatoos which is to be used for some really cool shader effects when spells are cast further down the line.</p>
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src={this.gifUrl("tatooMask")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block", "width":"50%"}}/>
                    </div>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">I then used Mixamo's auto rigger for quick general rigging, and adjusted weight paints as necessary in 3ds Max (mostly around the head, arms and fingers). I added additional bones for the wrist-twising, as I found Mixamo's solution insufficient.</p>
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src={this.gifUrl("weights")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block", "width":"50%"}}/>
                    </div>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">I used unity's inbuilt constraint system to fix the wrists.</p>
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src={this.gifUrl("wrists")} alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                    </div>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">Finally the avatar is available for use in VR!</p>
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src="\assets\images\portfolio\unityAvatar.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                        <img src="https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2Fbg%2Fbg-gif-6.gif?alt=media&token=9395e3b4-fd29-400e-bee0-839826371e3f" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                    </div>
                </div>
                {/* End Portfolio Details */}
                </Post>

            </React.Fragment>
        )
    }
}
export default MagicGame;
