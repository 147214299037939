import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import {webMFormattedSimple} from "../../helpers/resourceFinder";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {Grid, Image} from 'semantic-ui-react';
import 'react-pro-sidebar/dist/css/styles.css';

class KeysOfObliteration extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          scrollPos: 0,
          video: '',
          videoStart: 0,
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {      
        this.setState({
            scrollPos: window.scrollY,
        })
    }

    openModal (videoURL, start = 0) {
        this.setState({isOpen: true, video:videoURL, videoStart:start})
    }
    render(){
        return(
            <React.Fragment>
                
                <Post shortname='keysOfOb' sidebar={<ProSidebar toggled="false" id="portfolio-sidebar" style={this.state.scrollPos < 600 ? {"margin-left":"-12%"} : {}}>
                        <Menu iconShape="square">
                            <MenuItem><a href="#desc">Development</a></MenuItem>
                            <MenuItem><a href="#game">Final Game</a></MenuItem>
                        </Menu>
                    </ProSidebar>}>
                    
                    {/* For playing videos */}
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.video} youtube={{start:this.state.videoStart}} onClose={() => this.setState({isOpen: false})} />
                        </div>
                    </div>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p>This was done for Round 5 of Carnegie Mellon University's Building Virtual Worlds course.</p>
                        <br id="desc"/>

                        <p className="subtitle">
                            <br/><b>Platform:</b> A MIDI Keyboard and 3 monitors (PC).
                            <br/><b>Timeframe:</b> Two weeks.
                            <br/><b>Teammates:</b>Lochlan Belford (other programmer), Ruizi Wang (artist), Na Hyun Kim (artist), Max Cameron (sound designer).
                        </p>
                        <p className="subtitle">Brainstorming with this team was, to put it lightly, a wildly fun time. </p>
                        <p className="subtitle">Lochlan and I team-coded the main spellcasting mechanics, and we hooked them up to MIDI input. I coded the transition from standard mode to the boss fight, and the boss' attack mechanics.</p>
                        <p className="subtitle">I made a bunch of cool shaders and effects for this one. I made fragment shaders for the radially glowing runes, the spell orbs, the dissolving keys, the exploding ball, the goals and shields, the fire-breathing and the cobras on the sides.</p>
                        <br/><br id="game"/><br/><br/>
                        <h2>The Keys of Obliteration!</h2>
                        <Image src="\assets\images\portfolio\KeysCover.png" alt="Portfolio Images" style={{width:"100%"}}/>
                        <p className="subtitle">
                            <br/><b>Instruction video: </b>
                        </p>
                            <div className="thumb position-relative mb--30">
                                <img src="\assets\images\portfolio\KeysInstructionsThumb.PNG" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                                <button className="video-popup position-top-center" onClick={() => this.openModal('wF9Rt2n2Do4')}><span className="play-icon"></span></button>
                            </div>
                        <p className="subtitle">
                            <br/><b>Gameplay video: </b>
                        </p>
                            <div className="thumb position-relative mb--30">
                                <img src="\assets\images\portfolio\KeysVideoThumb.PNG" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                                <button className="video-popup position-top-center" onClick={() => this.openModal('zHJv9uQ3CNY')}><span className="play-icon"></span></button>
                            </div>
                        <br/><br/>
                        
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default KeysOfObliteration;
