import React from "react";

export function backgroundGif(number){
    return ("https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2Fbg%2Fbg-gif-"+number+".webm?alt=media");
}

export function backgroundGifFormatted(number, style={}, vidWidth=1920){
    var vidStyle = {"opacity":"1", "height":"100%", "width":"100%", "position":"absolute", "margin-top":"-120px"}
    Object.keys(style).forEach(function(key) {
        vidStyle[key] = style[key];
    });
    return (
        <div style={vidStyle}>
                        <video loop autoPlay muted style={{"min-width":"100%", "margin-left":"min((100% - "+vidWidth+"px)/2,0px)"}}>
                            <source src={backgroundGif(number)}/>
                        </video>
                    </div>)
}

export function webMFormatted(urlLocation, style={}, bgCol, opacity=1){
    var vidStyle = {"opacity":1, "height":"100%", "width":"100%", "position":"absolute"}
    Object.keys(style).forEach(function(key) {
        vidStyle[key] = style[key];
    });
    return (<div style={{"background-color":bgCol, "height":"100%", "width":"100%", "opacity":opacity}}>
        <div style={vidStyle}>
                        <video loop autoPlay muted style={{"width":"100%", "height":"100%"}}>
                            <source src={urlLocation}/>
                        </video>
                    </div>
                    </div>)
}

export function webMFormattedSimple(simpleName, style={}, bgCol, opacity=1){
    return webMFormatted('https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/gifs%2F'+simpleName+'.webm?alt=media&token=12f3df11-1186-4aeb-abbe-3860a8c3b4db', style, bgCol, opacity);
}