import React from "react";
import {isMobile} from 'react-device-detect';
import Unity, { UnityContext } from "react-unity-webgl";
 
const unityContext = new UnityContext({
  loaderUrl: "assets/iamProject2/IAMP2.loader.js",
  dataUrl: "assets/iamProject2/IAMP2.data",
  frameworkUrl: "assets/iamProject2/IAMP2.framework.js",
  codeUrl: "assets/iamProject2/IAMP2.wasm",
});
 
const IAMProject2 = () => {
    if (detectWebGLContext /*&& !isMobile*/)
        return <div style={{textAlign:"center", backgroundColor:"black", position:"absolute", top:"0", bottom:"0", right:"0", left:"0"}}><Unity height={"100%"} width={"100%"} unityContext={unityContext} /></div>;
    /*else if (isMobile)
      return <div> This content is unavailable on mobile.</div>*/
    else
      return <div>You need a browser that supports webGL.</div>
};

function detectWebGLContext () {
    // Create canvas element. The canvas is not added to the
    // document itself, so it is never displayed in the
    // browser window.
    var canvas = document.createElement("canvas");
    // Get WebGLRenderingContext from canvas element.
    var gl = canvas.getContext("webgl")
      || canvas.getContext("experimental-webgl");
    // Report the result.
    if (gl && gl instanceof WebGLRenderingContext) {
      return true;
    } else {
      return false;
    }
  }

export default IAMProject2;
