import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";

class SOPGame extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <Post shortname='sop'>
                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p className="subtitle">Near the end of my undergraduate degree, I fell in love with Carnegie Mellon University's Entertainment Technology Center. Their 2 year Master of Entertainment Technology degree seemed like exactly what I needed to hone my development skills and gain experience working in teams of some highly talented creative types! </p>
                        <p className="subtitle">I poured all my attention into my application - studying the GRE, building this website and working on my grades. However, when it came time to write my statement of purpose, I blanked on how to stand out. </p>
                        <p className="subtitle">Then I had an idea: This program is known for the rapid development of projects under tough time constraints. Why not accompany my two-page letter with a game?</p>
                        <p>Surely that's not cheating... Right?</p>
                        <p className="subtitle">This game was made in a very short time, and sought to embody the 3 areas of the ETC's R&D agenda. You can find details <a href="https://www.etc.cmu.edu/learn/rd-agenda/">here</a>.</p>
                        <p className="subtitle">The game achieved it's purpose! Or at least it didn't hurt it. I've never been more ecstatic over an acceptance letter. These next two years should be fun!</p>
                        <p className="subtitle">It's available at <a href="purpose">fariselrayes.com/purpose</a></p>.
                        
                    </div>
                    
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}
export default SOPGame;
