import React, { Component } from "react";

class RevealAfterPeriod extends Component{
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }
    
    componentDidMount() {
        this.interval = setInterval(() => this.showAll(), this.props.period*1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    showAll(){
        this.setState({visible:true});
        this.screenBottom.scrollIntoView({behavior:"smooth"});
    }

    render(){

        return(
            <React.Fragment>
                {
                    this.state.visible ?
                    this.props.children
                    :
                    null
                }       
                <div id="screenBottom" ref={(el) => { this.screenBottom = el; }}/>    
            </React.Fragment>
        )
    }
}
export default RevealAfterPeriod;
