import React, { Component } from "react";

class StoryText extends Component{
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    
    componentDidMount() {

    }
    componentWillUnmount() {
        
    }

    render(){
        return(
            <React.Fragment>

            </React.Fragment>
        )
    }
}
export default StoryText;
