import React, { Component } from "react";
import Post from "./Templates/post";
import ModalVideo from 'react-modal-video';
import { Document, Page, pdfjs } from 'react-pdf';
import throttle from "lodash.throttle"


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class Intertwined extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          numPages: null,
          pageNumber: 1,
          width: null
          
        }
        this.throttledSetDivSize = throttle(this.setDivSize, 500)
        this.openModal = this.openModal.bind(this)
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    onDocumentLoadSuccess({ nPages }) {
        console.log(nPages);
        this.setState({numPages: nPages});
    }
    componentDidMount () {
        this.setDivSize()
        window.addEventListener("resize", this.throttledSetDivSize)
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.throttledSetDivSize)
    }

    setDivSize = () => {
        this.setState({width: this.pdfWrapper.getBoundingClientRect().width})
    }
    render(){
        return(
            <React.Fragment>
                <Post shortname='intertwined'>

                {/* Start Portfolio Details */}
                <div className="portfolio-details">
                    <div className="inner">
                        <p className="subtitle">This was a brief game created as an assignment during my undergraduate degree. I worked with the amazing <a href="https://www.facebook.com/bodhihawken/">Bodhi Hawken</a> two develop a two-part narrative, where I was primarily responsible for part 1.</p>
                        <h2>Video Demo</h2>
                        <br/>                        
                    </div>
                </div>
                <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src="/assets/images/portfolio/intertwinedVidThumb.png" alt="Portfolio Images" style={{"margin-left": "auto","margin-right": "auto","display": "block"}}/>
                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='BzRjcXw9QE0' onClose={() => this.setState({isOpen: false})} />
                        <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                    </div>
                    <br/><br/>
                </div>
                <div className="portfolio-details">
                    <div className="inner">
                        <h2>Downloads</h2>  
                        <table>
                            <tr>
                                <th></th>
                                <th>Windows</th>
                                <th>MacOS</th>
                            </tr>
                            <th>Download Link</th>
                            <tr>
                                <td></td>
                                <td><a href="https://drive.google.com/file/d/1hDHsasYR2_yWpG9Y7nkl9azQ6JWUnuEB/view?usp=sharing">here</a></td>
                                <td><a href="https://drive.google.com/file/d/1siCbW_WpVlP1_uyc0AvlLb07mq34G73v/view?usp=sharing">here</a></td>
                            </tr>
                            <th>Minimum Requirements</th>
                            <tr>
                                <th>OS</th>
                                <td>Windows 7 (SP1+) or Windows 10</td>
                                <td>High Sierra 10.13+</td>
                            </tr>
                            <tr>
                                <th>CPU</th>
                                <td>X86 or X64 architecture</td>
                                <td>X86 or X64 architecture</td>
                            </tr>
                            <tr>
                                <th>Graphics</th>
                                <td>DX11 or DX12 with shader model 5.0</td>
                                <td>Metal-capable Intel and AMD GPUs</td>
                            </tr>
                            <tr>
                                <th>RAM</th>
                                <td>1GB</td>
                                <td>1GB</td>
                            </tr>
                        </table>
                        <h3>Instructions</h3>
                        <p>For menus such as the main menu or dialogue, your Mouse will be required to select desired options.</p>
                        <p>Throughout the game, Keyboard WASD controls are used to walk. From the direction you're facing, W walks you forward, A left, D right and S backwards. Your Mouse is used to turn your head.</p>
                        <h2>Original Shaders</h2>
                        <p className="subtitle">I produced several original shaders for this project, as improving my shader art skills was my primary goal. Rather than going through them here, I've included the report submitted alongside this project, which describes each of them with screenshots to the corresponding ShaderGraph.</p>
                    </div>
                </div>
                {/* End Portfolio Details */}

                <div id="pdfWrapper" style={{width: "100%"}} ref={(ref) => this.pdfWrapper = ref}>
                    <Document
                            file={"https://firebasestorage.googleapis.com/v0/b/faris-portfolio.appspot.com/o/pdfs%2FIntertwined%20Report.pdf?alt=media&token=c2953b18-7fe1-43aa-9c2d-22c0d9bf5150"}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                        >
                            {[...Array(18)].map((x, i) =>
                                <Page pageNumber={i+1} width={this.state.width}/>
                            )}
                        </Document>
                </div>

                </Post>

            </React.Fragment>
        )
    }
}
export default Intertwined;
