import React, { Children } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../../component/common/Helmet";
import HeaderThree from "../../component/header/HeaderThree";
import PortfolioList from "../../elements/portfolio/PortfolioList";
import {projectList, projectListOf} from "../../elements/helpers/projects";

const PortfolioPage = (props) => {
    return (
        <div className="active-dark">
            <Helmet pageTitle="Faris' Site" />
            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/> 

            {/* Start Portfolio Area */}
            <div id="portfolio" className="fix">
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <br/><br/><br/>
                            <div className="row">
                                {props.children}
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">{props.title}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList imgOpacity="0.8" styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" list={props.list ? projectListOf(props.list) : projectList} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}


            {/*<FooterTwo />*/}
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}

export default PortfolioPage;
